import Vue from 'vue'
import Vuex from 'vuex'
import VuexEasyFirestore from 'vuex-easy-firestore'


Vue.use(Vuex)

import {
  firebase,
  initFirebase
} from '../db.js'

import authModuleConfig from '@/store/auth'
import clientsModule from '@/store/modules/clientsModule'
import reportsModule from '@/store/modules/reportsModule'
import yearlyReportsModule from './modules/yearlyReportsModule.js'
import selectionModuleConfig from '@/store/selection'


// do the magic 🧙🏻‍♂️
const easyProjectFirestore = VuexEasyFirestore(
  [
    clientsModule,
    reportsModule,
    yearlyReportsModule

  ], {
    logging: true,
    FirebaseDependency: firebase
  }
)

// include as PLUGIN in your vuex store
// please note that "myModule" should ONLY be passed via the plugin
const storeData = {
  namespaced: true,
  modules: {
    auth: authModuleConfig,
    selection: selectionModuleConfig,
  },
  plugins: [easyProjectFirestore]
  // ... your other store data
}

const store = new Vuex.Store(storeData)

initFirebase().catch(error => {
  console.log("FATAL ERROR")
  Vue.$log.fatal(error)
  // take user to a page stating an error occurred``
  // (might be a connection error, or the app is open in another tab)
})

export default store