import {} from 'vuex'
// import state from 'vuex-easy-firestore/types/module/state'

export default {
    namespaced: true,
    state: {
        reportPage: null,
        boolPreviewReport: false,
        boolChecksReport: false,
        packageVersion: process.env.PACKAGE_VERSION || '0'

    },
    getters: {
        reportPage: state => state.reportPage,

        boolPreviewReport: state => state.boolPreviewReport,
        boolChecksReport: state => state.boolChecksReport,

        appVersion: state=>state.packageVersion
    },
    mutations: {
        setReportPage(state, payload) {
            //  this.$log.debug("mutation: new project: ", payload)
            state.reportPage = payload
        },

        setPreviewReport(state, payload){
            state.boolPreviewReport = payload
        },

        setChecksReport(state, payload){
            state.boolChecksReport = payload
        },

        togglePreviewReport(state) {
            state.boolPreviewReport = !state.boolPreviewReport
        },

        toggleChecksReport(state){
            state.boolChecksReport = !state.boolChecksReport
        }

    },
    actions: {}
}