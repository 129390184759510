<template>
  <div>
    <b-loading :active.sync="isGeneratingReport"></b-loading>
    <div v-if="!Boolean(data) || !Boolean(client)">
      <h1 class="title has-text-centered">{{ reportTitle }}</h1>
      <h2>Eroare, date lipsa</h2>
    </div>

    <div v-else id="report-content" class="content has-text-left">
      <h1 class="title has-text-centered">{{ reportTitle }}</h1>
      <h2 class="subtitle has-text-centered">{{ reportSubtitle }}</h2>

      <h4 class="subtitle has-text-centered">{{ reportPeriod }}</h4>

      <h5 v-if="versionMismatch">
        Atenție: raportul este v{{ reportVersion }} dar formularul este v{{
          formVersion
        }}!!
      </h5>
      <p v-for="err in errors" v-bind:key="err">{{ err }}</p>

      <p>
        Comptroller SRL, reprezentată prin Ene Mihaela, în calitate de cenzor
        pentru
        {{ clientData.name_official }}, cu sediul în {{ clientData.address }},
        am analizat și verificat documentele care stau la baza întocmirii
        rapoartelor și listelor de plată pentru luna {{ reportPeriod }}, conform
        cu Legea nr. 196/2018 privind înfiinţarea, organizarea şi funcţionarea
        Asociaţiilor de Proprietari.
      </p>

      <!-- ---------------REPARTIZAREA FACTURILOR ------------------ -->
      <p class="is-size-4">Repartizarea facturilor</p>
      <p>
        Suma totală a facturilor repartizate în listă este în valoare de
        {{ reportData.repartizarea_facturilor_suma_facturilor_repartizate }}
        RON, iar totalul lunar din lista de plată este în valoare de
        {{ reportData.repartizarea_facturilor_total_lunar_lista_de_plata }} RON.
      </p>

      <template v-if="sumaCotelorVsSumaFacturilorRepartizateEsteOk">
        <p>
          Suma totală a facturilor repartizate în listă este egală/aproximativ
          egală cu totalul lunar din lista de intreținere.
        </p>
      </template>

      <template v-else>
        <p>
          Diferența între suma cotelor apartamentelor și suma facturilor
          repartizate în listă este
          {{
            this.reportData
              .repartizarea_facturilor_suma_facturilor_repartizate -
            this.reportData.repartizarea_facturilor_total_lunar_lista_de_plata
          }}
          RON.
        </p>

        <p><strong>Observații</strong>:</p>
        <ul>
          <li
            v-for="item in formatListItems(
              reportData.repartizarea_facturilor_neconcordante_sume
            )"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
      </template>

      <template
        v-if="
          reportData.repartizarea_facturilor_facturile_din_liste_sunt_corecte ==
          'true'
        "
      >
        <p>
          Toate facturile repartizate în liste există fizic și sunt trecute cu
          sumele și datele corecte.
        </p>
      </template>
      <template v-else>
        <p>
          <strong>Observații</strong> cu privire la repartizarea facturilor:
        </p>
        <ul>
          <li
            v-for="item in formatListItems(
              reportData.repartizarea_facturilor_neconcordante_facturi_din_liste
            )"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
      </template>

      <template
        v-if="
          reportData.repartizarea_facturilor_facturile_din_fonduri_sunt_corecte ==
          'true'
        "
      >
        <p>
          Toate facturile achitate din fonduri există fizic și sunt trecute cu
          sumele și datele corecte.
        </p>
      </template>

      <template v-else>
        <p>
          <strong>Observații</strong> cu privire la facturile achitate din
          fonduri:
        </p>
        <ul>
          <li
            v-for="item in formatListItems(
              reportData.repartizarea_facturilor_neconcordante_facturi_din_fonduri
            )"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
      </template>

      <!-- ---------------REGISTRUL DE CASA ------------------ -->

      <p class="is-size-4">Registrul de casă:</p>
      <p>
        Soldul de la casă este în valoare de
        {{ reportData.registrul_de_casa_sold_casa }} RON. Nu s-a efectuat
        numărarea lichidităților.
      </p>

      <template
        v-if="reportData.registrul_de_casa_incasarile_sunt_corecte == 'true'"
      >
        <p>
          Toate încasările în numerar sunt trecute cu sumele și datele corecte
          în Registrul de casă.
        </p>
      </template>

      <template v-else>
        <p>
          <strong>Observații</strong> cu privire la încasarile din Registrul de
          casă:
        </p>
        <ul>
          <li
            v-for="item in formatListItems(
              reportData.registrul_de_casa_neconcordante_incasari
            )"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
      </template>

      <template
        v-if="reportData.registrul_de_casa_platile_sunt_corecte == 'true'"
      >
        <p>
          Toate plățile în numerar sunt trecute cu sumele și datele corecte în
          Registrul de casă.
        </p>
      </template>

      <template v-else>
        <p>
          <strong>Observații</strong> cu privire la plațile din Registrul de
          casă:
        </p>
        <ul>
          <li
            v-for="item in formatListItems(
              reportData.registrul_de_casa_neconcordante_plati
            )"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
      </template>

      <template
        v-if="reportData.registrul_de_casa_este_corect_pe_zile == 'true'"
      >
        <p>
          Fiecare zi trecuta în Registrul de casă are totalul de plăti corect,
          totalul de încasări corect și totalul zilnic corect.
        </p>
      </template>
      <template v-else>
        <p><strong>Observații</strong> cu privire la Registrul de casă:</p>
        <ul>
          <li
            v-for="item in formatListItems(
              reportData.registrul_de_casa_neconcordante_pe_zile
            )"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
      </template>

      <template
        v-if="
          parseInt(reportData.registrul_de_casa_sold_casa) > 1000 &&
          reportData.popriri_conturi == 'true'
        "
      >
        <p>
          Soldul de la casă depașește plafonul stabilit de lege în valoare de
          1000 de RON, dar valoarea depașită nu poate fi depusă în contul de la
          bancă deoarece pe contul Asociației există popriri.
        </p>
      </template>

      <template
        v-if="
          parseInt(reportData.registrul_de_casa_sold_casa) > 1000 &&
          reportData.popriri_conturi == 'false'
        "
      >
        <p>
          Soldul de la casă este mai mare decât plafonul prevăzut de lege, în
          valoare de 1000 de RON;
          <strong>recomandăm</strong> depunerea diferenței în contul de la
          bancă.
        </p>
      </template>

      <!-- ---------------REGISTRUL DE BANCA ------------------ -->

      <p class="is-size-4">Registrul de bancă:</p>
      <template
        v-if="reportData.registrul_de_banca_vs_extras_cont_este_ok == 'true'"
      >
        <p>
          Extrasul de cont de la bancă este inregistrat corect cu datele și
          sumele corecte în Registrul de bancă/Registrul jurnal.
        </p>
      </template>
      <template v-else>
        <p><strong>Observații</strong> cu privire la Registrul de bancă:</p>
        <ul>
          <li
            v-for="item in formatListItems(
              reportData.registrul_de_banca_neconcordante
            )"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
      </template>

      <template v-if="!!reportData.registrul_de_banca_sold_banca">
        <p>
          Soldul de la bancă este în valoare de
          {{ reportData.registrul_de_banca_sold_banca }} RON.
        </p>
      </template>
      <template v-else>
        <p>LIPSEȘTE soldul de la bancă !!!</p>
      </template>

      <!-- ---------------ACTIV/PASIV ------------------ -->

      <p class="is-size-4">Situația de Activ/Pasiv:</p>
      <p>
        Totalul elementelor de Activ este în valoare de:
        {{ reportData.activ_pasiv_total_activ }} RON.
      </p>
      <p>
        Totalul elementelor de Pasiv este în valoare de:
        {{ reportData.activ_pasiv_total_pasiv }} RON.
      </p>

      <p>
        Soldul din Registrul de bancă la data emiterii Situației de Activ/Pasiv
        este {{ reportData.activ_pasiv_sold_banca }} RON.
      </p>
      <p>
        Soldul din Registrul de casă la data emiterii Situației de Activ/Pasiv
        este {{ reportData.activ_pasiv_sold_casa }} RON.
      </p>

      <template v-if="situatiaActivePasiveEsteEchilibrata">
        <p>Situația de Activ/Pasiv este echilibrată.</p>
      </template>

      <template v-else>
        <p>
          <strong>Observații</strong> cu privire la situația de Activ/Pasiv:
        </p>
        <ul>
          <li
            v-for="item in formatListItems(
              reportData.activ_pasiv_neconcordante
            )"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
      </template>

      <!-- ---------------STATUL DE SALARII ------------------ -->

      <p class="is-size-4">Ștatul de salarii:</p>
      <template
        v-if="reportData.statul_de_salarii_contributii_corecte == 'true'"
      >
        <p>Contribuțiile calculate pe ștatul de plată sunt corecte.</p>
      </template>

      <template v-else>
        <p>
          <strong>Observații</strong> cu privire la calculul contribuțiilor pe
          ștatul de plată:
        </p>
        <ul>
          <li
            v-for="item in formatListItems(
              reportData.statul_de_salarii_neconcordante_contributii
            )"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
      </template>

      <template v-if="reportData.statul_de_salarii_d112_este_corecta == 'true'">
        <p>Declarația 112 este întocmită corespunzător.</p>
      </template>

      <template v-else>
        <p>
          <strong>Observații</strong> cu privire la calculul contribuțiilor pe
          ștatul de plată:
        </p>
        <ul>
          <li
            v-for="item in formatListItems(
              reportData.statul_de_salarii_neconcordante_d112
            )"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
      </template>

      <template
        v-if="reportData.statul_de_salarii_recipisa_d112_este_ok == 'true'"
      >
        <p>
          Există recipisa de depunere fără erori de validare pentru Declaratia
          112.
        </p>
      </template>

      <template v-else>
        <p>
          <strong>Nu am primit</strong> recipisa de depunere fără erori de
          validare pentru Declaratia 112.
        </p>
      </template>
    </div>
  </div>
</template>

<script>
// import { format, parseISO } from "date-fns";
// import roLocale from "date-fns/locale/ro";

export default {
  name: "ComptrollerReportV4",

  props: {
    data: Object,
    client: Object,
    formVersion: String,
  },

  data() {
    return {
      reportUrl: null,
      isGeneratingReport: false,
      // the version of the report component
      // must match the versio of the form used to collect the report data
      reportVersion: "4",
    };
  },

  computed: {
    reportData() {
      return this.data || {};
    },
    clientData() {
      return this.client || {};
    },
    reportTitle() {
      return "RAPORT LUNAR DE VERIFICARE AL CENZORULUI";
    },
    reportSubtitle() {
      return `pentru ${this.clientData.name_official}`;
    },

    versionMismatch() {
      return this.reportVersion !== (this.formVersion || "4");
    },

    errors() {
      let requiredClientProperties = ["name_official", "address"];
      let requiredReportProperties = [
        "report_year",
        "report_month",
        "asociatia_exclusiv_banca",
        "popriri_conturi",
        "primit_registrul_de_casa",
        "primit_statul_de_plata",
        "asociatia_are_angajati",
        "repartizarea_facturilor_suma_facturilor_repartizate",
        "repartizarea_facturilor_total_lunar_lista_de_plata",
        "repartizarea_facturilor_facturile_din_liste_sunt_corecte",
        "repartizarea_facturilor_facturile_din_fonduri_sunt_corecte",
        "registrul_de_casa_incasarile_sunt_corecte",
        "registrul_de_casa_platile_sunt_corecte",
        "registrul_de_casa_este_corect_pe_zile",
        "registrul_de_casa_sold_casa",
        "registrul_de_banca_vs_extras_cont_este_ok",
        "registrul_de_banca_sold_banca",
        "activ_pasiv_total_activ",
        "activ_pasiv_total_pasiv",
        "activ_pasiv_sold_casa",
        "activ_pasiv_sold_banca",
        // "activ_pasiv_neconcordante",

        "statul_de_salarii_contributii_corecte",
        "statul_de_salarii_d112_este_corecta",
        "statul_de_salarii_recipisa_d112_este_ok",
      ];

      // requiredClientProperties.filter(prop => this.clientData[prop] === null);
      let errors = [].concat(
        requiredClientProperties.filter(
          (prop) => !Object.prototype.hasOwnProperty.call(this.client, prop)
        ),
        requiredReportProperties.filter(
          (prop) => !Object.prototype.hasOwnProperty.call(this.data, prop)
        )
      );

      return errors.map((err) => `Eroare: lipseste campul ${err}`);
    },

    reportPeriod() {
      if (
        !this.reportData ||
        !this.reportData.report_year ||
        !this.reportData.report_month
      )
        return "DATA LIPSA";

      const months = {
        1: "Ianuarie",
        2: "Februarie",
        3: "Martie",
        4: "Aprilie",
        5: "Mai",
        6: "Iunie",
        7: "Iulie",
        8: "August",
        9: "Septembrie",
        10: "Octombrie",
        11: "Noiembrie",
        12: "Decembrie",
      };
      return [
        months[this.reportData.report_month],
        this.reportData.report_year,
      ].join(" ");
    },

    sumaCotelorVsSumaFacturilorRepartizateEsteOk() {
      return (
        Math.abs(
          this.reportData.repartizarea_facturilor_suma_facturilor_repartizate -
            this.reportData.repartizarea_facturilor_total_lunar_lista_de_plata
        ) < 2
      );
    },

    situatiaActivePasiveEsteEchilibrata() {
      return (
        Math.abs(
          this.reportData.activ_pasiv_total_activ -
            this.reportData.activ_pasiv_total_pasiv
        ) < 2
      );
    },
  },
  methods: {
    formatListItems(text) {
      return (text || "lipsa observatii !!!!").trim().split("\n");
    },
  },
};
</script>

<style scoped lang="css">
</style>