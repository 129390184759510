var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "section"
  }, [_c('x-survey', {
    attrs: {
      "content": _vm.formJson,
      "data": _vm.localData,
      "selectedPage": _vm.selectedReportPage,
      "readOnly": false
    },
    on: {
      "completed": _vm.onFormSubmitted,
      "activePageChange": _vm.setActiveReportPage,
      "update": _vm.onFormUpdated
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }