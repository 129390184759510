// where clause for reading from firestore
// const where = [
//   ['created_by', '==', '{userId}'],
// ]

const reportsModule = {
  firestorePath: `clients/{clientId}/reports`,
  firestoreRefType: 'collection', // or 'doc'
  // name for vuex module
  moduleName: 'reports',
  statePropName: 'data',
  namespaced: true, // automatically added

  serverChange: {
    convertTimestamps: {
      created_at: '%convertTimestamp%', // default
      updated_at: '%convertTimestamp%', // default
      // define each field like so ↑ in an object here
    },
  },

  sync: {
    preventInitialDocInsertion: true,
    // where,
    // orderBy
  },

  // this object is your store module (will be added as '/surveyFormModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    allAsArray: state => Object.values(state.data || {}).sort((a, b) => (a.name || "").localeCompare(b.name || "")),

    allAsObject: state => state.data || {},

    getById: state => (id) => {
      const res = state.data[id]
      if (res) return res
      console.log(`WARNING: reportsModule: could not find id:`, id)
      return null
    }
  },
  mutations: {},
  actions: {},
}

export default reportsModule