<template>
  <section class="section">
    <FormulateForm v-model="localData" @submit="handleSubmit">
      <FormulateInput type="text" name="title" label="nume" />
      <FormulateInput
        type="text"
        name="name_official"
        label="denumirea oficială"
      />
      <FormulateInput type="textarea" name="address" label="addresa" />
      <FormulateInput
        type="radio"
        name="d112_frequency"
        label="depune D112:"
        :options="{ lunar: 'lunar', trimestrial: 'trimestrial' }"
      />

      <FormulateInput type='date' name="contract_start_date" label='Data start a contractului'/>
      <FormulateInput type='month' name="first_month" label='Prima luna verificata (MMMM-YY)' validation="date:YYYY-MM"/>

      <FormulateInput type='date' name="contract_stop_date" label='Data stop a contractului'/>
      
      <FormulateInput type="radio" name="comptroller_entity" 
          :options="{ENTITY_COMPTROLLER:'Comptroller', ENTITY_THEMIS:'Themis Expert'}"
          validation="required"
      label="Entitate Cenzor"/>

      <FormulateInput type="button">Anulează</FormulateInput>
      <FormulateInput type="submit">Salvează</FormulateInput>
    </FormulateForm>
  </section>
</template>

<script>
import Client from "@/models/client";

export default {
  name: "ClientForm",
  props: {
    client: {
      type: Object,
      default: function () {
        return Client.newClient();
      },
    },
  },
  data: function () {
    return {
      localData: {},
    };
  },

  watch: {
    client: {
      deep: true,
      immediate: true,
      handler: function (newClient) {
        if (!newClient) {
          this.localData = Object.assign({}, Client.newClient());
          return;
        }

        this.localData = {
          id: newClient.id,
          name_official: newClient.name_official,
          title: newClient.title,
          address: newClient.address,
          d112_frequency: newClient.d112_frequency,
          first_month: newClient.first_month,
          contract_start_date: newClient.contract_start_date,
          contract_stop_date: newClient.contract_stop_date,
          comptroller_entity: newClient.comptroller_entity
        };
      },
    },
  },

  methods: {
    handleSubmit(data) {
      console.log("client form submit:", data);
      this.$emit("saved", data);
    },
  },
};
</script>

<style>
</style>