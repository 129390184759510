<template>
  <div class="section">
    <div>
      <client-form :client="client" @saved="onFormCompete"></client-form>
    </div>
    <div>
      <b-button @click="askForConfirmationTheDelete">Sterge asociatia</b-button>
    </div>

    <!-- show while trying to submit answer to server -->
    <b-loading
      :is-full-page="isFullPage"
      :active.sync="savingResult"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import Client from "@/models/client";
import ClientForm from "@/components/ClientForm.vue";
import { mapGetters, mapActions } from "vuex";

// import XSurvey from "../../components/XSurvey.vue";
// import { ToastProgrammatic as Toast } from 'buefy'
export default {
  name: "AddClientPage",

  components: { ClientForm },
  data: function () {
    return {
      isFullPage: false,
      savingResult: false,
      clientForm: Client.form,
    };
  },

  computed: {
    clientId() {
      return this.$route.params.clientId;
    },
    ...mapGetters({ getClientById: "clients/getById" }),
    client() {
      if (!this.clientId) return null;
      return this.getClientById(this.clientId);
    },
  },
  methods: {
    deleteProject(id) {
      return this.$store.dispatch("project/delete", id);
    },
    ...mapActions({ deleteClient: "clients/delete" }),

    async onFormCompete(newClient) {
      console.log("form has completed, data is : ", newClient);

      this.saveInProgress = true;

      try {
        // save the project to Firestore
        const newId = await this.$store.dispatch("clients/patch", newClient);
        console.log("saved project with id ", newId, newClient);

        this.$router.push({ name: "ViewClient", params: { clientId: newId } });
      } catch (error) {
        // this.track('error', {
        //   type: 'error saving client',
        //   error: error,
        //   project: newClient
        // })
        console.log("ERROR saving new client", error);
        this.saveInProgress = false;
        this.savingError = error;
      }
    },

    askForConfirmationTheDelete() {
      this.$buefy.dialog.confirm({
        title: "Sigur vrei sa ștergi asociația?",
        message:
          "Vei pierde toate datele si mesajese primite. Nu se pot recupera!",
        cancelText: "Nu",
        confirmText: "Șterge!",
        type: "is-danger",
        onConfirm: this.deleteAndNotify,
      });
    },
    async deleteAndNotify() {
      try {
        await this.deleteClient(this.client.id);

        this.$buefy.toast.open({
          message: `Am șters asociația`,
          type: "is-success",
          duration: 5000,
        });
        this.$router.replace({ name: "ListClients" });
      } catch (error) {
        console.log(error);
        this.$buefy.toast.open({
          message: `Nu am putut sterge asociația: ${
            this.client.name || "fara nume"
          }`,
          type: "is-danger",
          duration: 5000,
        });
      }
    },
  },
};
</script>

<style>
</style>
