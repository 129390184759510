<template>
  <div>
    <!-- show while trying to submit answer to server -->

    <b-loading
      :is-full-page="isFullPage"
      :active="!client"
      :can-cancel="false"
    ></b-loading>

    <section class="section" v-if="!!client">
      <div class="columns is-centered">
        <div class="column">
          <div>
            <h1 class="title is-size-2">{{ client.title ?? "Client fara nume" }}</h1>
            <h3>{{ client.name_official ?? "" }}</h3>
            <h3 class="pb-3">{{ client.address ?? "" }}</h3>
            <h3 class="pb-3">Depune D112: {{client.d112_frequency ?? ""}}</h3>

            <h3 >Data contractului: {{client.contract_start_date ?? ""}}</h3>
            <h3 >Prima luna: {{client.first_month ?? ""}}</h3>
            <h3 >Data stop a contractului: {{client.contract_stop_date ?? ""}}</h3>
            
            <h3 >ultima actualizare: {{ client.updated_at?.toDate() | dateFormat }}</h3>
            <h3 >Entitate Cenzor: {{ client.comptroller_entity ?? ""}}</h3>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="section">
      <list-of-reports :reports="reportsList"></list-of-reports>
    </section> -->
  </div>
</template>

<script>
import Client from "@/models/client";
// import ListOfReports from "@/components/ListOfReports";
import { mapGetters } from "vuex";
import {format} from 'date-fns'
// import { segmentMixin } from "@/mixins/segmentMixin";<script>
// import moment from 'moment'

// import { ToastProgrammatic as Toast } from 'buefy'
export default {
  name: "ViewClientPage",

  // components: { ListOfReports },
  created() {
    this.$store.dispatch("reports/openDBChannel", { clientId: this.clientId });
  },
  data: function () {
    return {
      isFullPage: false,
      savingResult: false,
    };
  },

  computed: {
    clientId() {
      return this.$route.params.clientId;
    },
    ...mapGetters({
      reportsList: "reports/allAsArray",
      getClientById: "clients/getById",
    }),
    client() {
      if (!this.clientId) return null;
      return this.getClientById(this.clientId);
    },
  },
  methods: {
    async onFormCompete(result) {
      console.log(
        "form has completed, data is : ",
        JSON.parse(JSON.stringify(result))
      );
      const newClient = Client.newClient(result.data);

      this.saveInProgress = true;

      try {
        // save the project to Firestore
        const newId = await this.$store.dispatch("clients/set", newClient);
        console.log("saved project with id ", newId, newClient);

        // const storedProject = this.getProjectById(newId)
        // await this.selectProject(storedProject)
      } catch (error) {
        // this.track('error', {
        //   type: 'error saving client',
        //   error: error,
        //   project: newClient
        // })
        console.log("ERROR saving new client", error);
        this.saveInProgress = false;
        this.savingError = error;
      }
    },

    buildObjectToSubmit(result) {
      const resultObject = Client.newClient("no name");
      resultObject.data = JSON.parse(JSON.stringify(result.data, null, 3));

      return resultObject;
    },
  },
  filters: {
    dateFormat(d) {
      let result;
      try {
        result = format(d, "yyyy-MM-dd HH:mm  ");
      } catch (error) {
        result = "";
      }
      return result;
    },
  },
};
</script>

<style>
</style>
