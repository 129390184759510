var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "section"
  }, [_c('FormulateForm', {
    on: {
      "submit": _vm.handleSubmit
    },
    model: {
      value: _vm.localData,
      callback: function ($$v) {
        _vm.localData = $$v;
      },
      expression: "localData"
    }
  }, [_c('FormulateInput', {
    attrs: {
      "type": "text",
      "name": "title",
      "label": "nume"
    }
  }), _c('FormulateInput', {
    attrs: {
      "type": "text",
      "name": "name_official",
      "label": "denumirea oficială"
    }
  }), _c('FormulateInput', {
    attrs: {
      "type": "textarea",
      "name": "address",
      "label": "addresa"
    }
  }), _c('FormulateInput', {
    attrs: {
      "type": "radio",
      "name": "d112_frequency",
      "label": "depune D112:",
      "options": {
        lunar: 'lunar',
        trimestrial: 'trimestrial'
      }
    }
  }), _c('FormulateInput', {
    attrs: {
      "type": "date",
      "name": "contract_start_date",
      "label": "Data start a contractului"
    }
  }), _c('FormulateInput', {
    attrs: {
      "type": "month",
      "name": "first_month",
      "label": "Prima luna verificata (MMMM-YY)",
      "validation": "date:YYYY-MM"
    }
  }), _c('FormulateInput', {
    attrs: {
      "type": "date",
      "name": "contract_stop_date",
      "label": "Data stop a contractului"
    }
  }), _c('FormulateInput', {
    attrs: {
      "type": "radio",
      "name": "comptroller_entity",
      "options": {
        ENTITY_COMPTROLLER: 'Comptroller',
        ENTITY_THEMIS: 'Themis Expert'
      },
      "validation": "required",
      "label": "Entitate Cenzor"
    }
  }), _c('FormulateInput', {
    attrs: {
      "type": "button"
    }
  }, [_vm._v("Anulează")]), _c('FormulateInput', {
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("Salvează")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }