<template>
  <b-table
    :data="localReports"
    :debounce-search="300"
    sort-icon="arrow-up"
    sort-icon-size="sortIconSize"
    default-sort="perioada"
  >
    <b-table-column field="perioada" label="perioada" sortable>
      <template v-slot="props">
        <!-- {{ props.row.perioada }} -->
        <router-link
          :to="{ name: 'ViewYearlyReport', params: { reportId: props.row.id } }"
          >{{ buildReportTitle(props.row.data) }}</router-link
        >
      </template>
    </b-table-column>

    <b-table-column field="created_at" label="creat" sortable>
      <template v-slot="props">{{
        props.row.created_at | dateFormat
      }}</template>
    </b-table-column>

    <b-table-column field="updated_at" label="modificat" sortable>
      <template v-slot="props">{{
        props.row.updated_at | dateFormat
      }}</template>
    </b-table-column>

    <b-table-column field="version" label="versiune" sortable>
      <template v-slot="props">{{
        props.row.formVersionString || "4"
      }}</template>
    </b-table-column>

    <b-table-column label="actiuni">
      <template slot="searchable" slot-scope="props">
        <b-input
          v-model="props.filters.address"
          placeholder="Search..."
          icon="magnify"
          size="is-small"
        />
      </template>

      <template v-slot="props">
        <div class="buttons">
          <b-button
            class="is-danger is-outlined"
            icon-left="trash"
            @click="confirmAndDeleteReport(props.row.id)"
            >Delete</b-button
          >
          <b-button
            class="is-success"
            icon-left="download"
            v-if="props.row.storagePath"
            @click="downloadReport(props.row)"
            >Descarcă</b-button
          >
        </div>
      </template>
    </b-table-column>

    <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon icon="emoticon-sad" size="is-large"></b-icon>
          </p>
          <p>Nothing here.</p>
        </div>
      </section>
    </template>
  </b-table>
</template>

<script >
// import { mapGetters } from "vuex";
import { format } from "date-fns";
import ForceDownload from "@/utils/forceDownload";
import YearlyReport from "@/models/yearlyReport";

export default {
  name: "ListOfYearlyReports",
 
  props: {
    reports: Array,
  },
  computed: {
    localReports(){
      return this.reports.map((report) => {
        let newReport = Object.assign({}, report);
        newReport.perioada = report.data.report_year ;
        return newReport;
      });
    }
  },

  filters: {
    dateFormat(d) {
      let result;
      try {
        result = format(d, "yyyy-MM-dd HH:mm  ");
      } catch (error) {
        result = "";
      }
      return result;
    },
  },

  methods: {
    confirmAndDeleteReport(reportId) {
      reportId;
      this.$buefy.dialog.confirm({
        title: "Ștergere raport",
        message:
          "Sigur vrei să <b>ștergi</b> acest raport? Nu va mai putea fi recuperat.",
        confirmText: "Șterge raport",
        type: "is-danger",
        hasIcon: true,
        icon: "exclamation-circle",
        onConfirm: () => this.$store.dispatch("yearlyReports/delete", reportId),
      });
    },

    buildReportTitle(reportData) {
      if (reportData && reportData.report_year)
        return reportData.report_year;

      return "nestabilită";
    },

    async downloadReport(report) {
      return ForceDownload.downloadFromFirebaseStorage(
        report.storagePath,
        "application/docx",
        YearlyReport.reportFilename(report)
      );
    },
  },
};
</script>

<style>
.container {
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.title {
  font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  display: block;
  font-weight: 300;
  font-size: 100px;
  color: #35495e;
  letter-spacing: 1px;
}

.subtitle {
  font-weight: 300;
  font-size: 42px;
  color: #526488;
  word-spacing: 5px;
  padding-bottom: 15px;
}

.links {
  padding-top: 15px;
}
</style>
