<template>
  <div>
    <b-loading :active.sync="isGeneratingReport"></b-loading>
    <div v-if="!Boolean(data) || !Boolean(client)">
      <h1 class="title has-text-centered">{{ reportTitle }}</h1>
      <h2>Eroare, date lipsa</h2>
    </div>

    <div v-else id="report-content" class="content has-text-left">
      <img src="https://cenzor-9f808.web.app/logo.jpeg" alt="logo" />
      <h1 class="title has-text-centered">{{ reportTitle }}</h1>
      <h2 class="subtitle has-text-centered">{{ reportSubtitle }}</h2>

      <h4 class="subtitle has-text-centered">
        {{ reportPeriod }}
        <!-- <span class="has-text-grey"
          >v{{ reportVersion }}-{{ formVersion }}</span
        > -->
      </h4>
      <h5></h5>

      <p v-for="err in errors" v-bind:key="err" class="has-text-danger">
        {{ err }}
      </p>

      <p>
        <strong>Comptroller SRL</strong>, reprezentată prin Ene Mihaela, în
        calitate de cenzor pentru <strong>{{ clientData.name_official }}</strong
        >, cu sediul în <strong>{{ clientData.address }}</strong
        >, am analizat și verificat documentele care stau la baza întocmirii
        rapoartelor și listelor de plată pentru luna
        <strong>{{ reportPeriod }}</strong
        >, conform cu Legea nr. 196/2018 privind înfiinţarea, organizarea şi
        funcţionarea Asociaţiilor de Proprietari.
      </p>

      <!-- ---------------REPARTIZAREA FACTURILOR ------------------ -->
      <p class="section-title">Repartizarea facturilor</p>
      <p>
        Suma totală a facturilor repartizate în listă este în valoare de
        {{
          typeof reportData.repartizarea_facturilor_suma_facturilor_repartizate ==
          "number"
            ? reportData.repartizarea_facturilor_suma_facturilor_repartizate
            : "___"
        }}
        RON, iar totalul lunar din lista de plată este în valoare de

        {{
          typeof reportData.repartizarea_facturilor_total_lunar_lista_de_plata ==
          "number"
            ? reportData.repartizarea_facturilor_total_lunar_lista_de_plata
            : "___"
        }}
        RON.
      </p>

      <template v-if="sumaCotelorVsSumaFacturilorRepartizateEsteOk">
        <p>
          Suma totală a facturilor repartizate în listă este egală/aproximativ
          egală cu totalul lunar din lista de intreținere.
        </p>
      </template>

      <template v-else>
        <p>
          Diferența între suma cotelor apartamentelor și suma facturilor
          repartizate în listă este
          {{
            this.reportData
              .repartizarea_facturilor_suma_facturilor_repartizate -
            this.reportData.repartizarea_facturilor_total_lunar_lista_de_plata
          }}
          RON.
        </p>

        <ul>
          <strong>Observații:</strong>
          <li
            v-for="item in formatListItems(
              reportData.repartizarea_facturilor_neconcordante_sume
            )"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
      </template>

      <template
        v-if="
          reportData.repartizarea_facturilor_facturile_din_liste_sunt_corecte ==
          'true'
        "
      >
        <p>
          Toate facturile repartizate în liste există fizic și sunt trecute cu
          sumele și datele corecte.
        </p>
      </template>
      <template v-else>
        <p>
          <strong>Observații</strong> cu privire la repartizarea facturilor:
        </p>
        <ul>
          <li
            v-for="item in formatListItems(
              reportData.repartizarea_facturilor_neconcordante_facturi_din_liste
            )"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
      </template>

      <template v-if="reportData.facturi_achitate_fonduri == 'true'">
        <template
          v-if="
            reportData.repartizarea_facturilor_facturile_din_fonduri_sunt_corecte ==
            'true'
          "
        >
          <p>
            Toate facturile achitate din fonduri există fizic și sunt trecute cu
            sumele și datele corecte.
          </p>
        </template>

        <template v-else>
          <p>
            <strong>Observații</strong> cu privire la facturile achitate din
            fonduri:
          </p>
          <ul>
            <li
              v-for="item in formatListItems(
                reportData.repartizarea_facturilor_neconcordante_facturi_din_fonduri
              )"
              :key="item"
            >
              {{ item }}
            </li>
          </ul>
        </template>
      </template>

      <!-- ---------------REGISTRUL DE CASA ------------------ -->

      <p class="section-title">Registrul de casă:</p>

      <template v-if="reportData.asociatia_exclusiv_banca == 'true'">
        <p>Asociația funcționează exclusiv prin bancă.</p>
      </template>

      <template v-else>
        <!-- doar pt asoc care nu merg exclusiv prin banca -->

        <template
          v-if="reportData.asociatia_operatiuni_cash_luna_raportului == 'false'"
        >
          <p>Nu au existat operatiuni in numerar in luna.</p>
        </template>

        <template v-else>
          <p>
            Numarul primei chitante inregistrate in luna este
            {{
              typeof reportData.registrul_de_casa_nr_prima_chitanta == "number"
                ? reportData.registrul_de_casa_nr_prima_chitanta
                : "___"
            }}.
          </p>
          <p>
            Numarul ultimei chitante inregistrate in luna este
            {{
              typeof reportData.registrul_de_casa_nr_ultima_chitanta == "number"
                ? reportData.registrul_de_casa_nr_ultima_chitanta
                : "___"
            }}.
          </p>

          <p>
            Soldul de la casă este în valoare de
            {{
              typeof reportData.registrul_de_casa_sold_casa == "number"
                ? reportData.registrul_de_casa_sold_casa
                : "___"
            }}
            RON. Nu s-a efectuat numărarea lichidităților.
          </p>

          <template
            v-if="
              reportData.registrul_de_casa_incasarile_sunt_corecte == 'true'
            "
          >
            <p>
              Toate încasările în numerar sunt trecute cu sumele și datele
              corecte în Registrul de casă.
            </p>
          </template>

          <template v-else>
            <p>
              <strong>Observații</strong> cu privire la încasarile din Registrul
              de casă:
            </p>
            <ul>
              <li
                v-for="item in formatListItems(
                  reportData.registrul_de_casa_neconcordante_incasari
                )"
                :key="item"
              >
                {{ item }}
              </li>
            </ul>
          </template>

          <template
            v-if="reportData.registrul_de_casa_platile_sunt_corecte == 'true'"
          >
            <p>
              Toate plățile în numerar sunt trecute cu sumele și datele corecte
              în Registrul de casă.
            </p>
          </template>

          <template v-else>
            <p>
              <strong>Observații</strong> cu privire la plațile din Registrul de
              casă:
            </p>
            <ul>
              <li
                v-for="item in formatListItems(
                  reportData.registrul_de_casa_neconcordante_plati
                )"
                :key="item"
              >
                {{ item }}
              </li>
            </ul>
          </template>

          <template
            v-if="reportData.registrul_de_casa_este_corect_pe_zile == 'true'"
          >
            <p>
              Fiecare zi trecuta în Registrul de casă are totalul de plăti
              corect, totalul de încasări corect și totalul zilnic corect.
            </p>
          </template>

          <template v-else>
            <p><strong>Observații</strong> cu privire la Registrul de casă:</p>
            <ul>
              <li
                v-for="item in formatListItems(
                  reportData.registrul_de_casa_neconcordante_pe_zile
                )"
                :key="item"
              >
                {{ item }}
              </li>
            </ul>
          </template>

          <template
            v-if="
              parseInt(reportData.registrul_de_casa_sold_casa) > 1000 &&
              reportData.popriri_conturi == 'true'
            "
          >
            <p>
              Soldul de la casă depașește plafonul stabilit de lege în valoare
              de 1000 de RON, dar valoarea depașită nu poate fi depusă în contul
              de la bancă deoarece pe contul Asociației există popriri.
            </p>
          </template>

          <template
            v-if="
              parseInt(reportData.registrul_de_casa_sold_casa) > 1000 &&
              reportData.popriri_conturi == 'false'
            "
          >
            <p>
              Soldul de la casă este mai mare decât plafonul prevăzut de lege,
              în valoare de 1000 de RON;
              <strong>recomandăm</strong> depunerea diferenței în contul de la
              bancă.
            </p>
          </template>

          <template v-if="diferentaSoldRegCasaVsActivPasiv">
            <p>
              <strong
                >Soldul de casa conform Registrului de casa nu este egal cu
                soldul de la casa conform Situatie de Activ/Pasiv. Diferența:
                {{ diferentaSoldRegCasaVsActivPasiv }} RON.
              </strong>
            </p>
          </template>
        </template>
      </template>
      <!-- ---------------REGISTRUL DE BANCA ------------------ -->

      <p class="section-title">Registrul de bancă:</p>
      <template
        v-if="reportData.registrul_de_banca_vs_extras_cont_este_ok == 'true'"
      >
        <p>
          Extrasul de cont de la bancă este inregistrat corect cu datele și
          sumele corecte în Registrul de bancă/Registrul jurnal.
        </p>
      </template>
      <template v-else>
        <p><strong>Observații</strong> cu privire la Registrul de bancă:</p>
        <ul>
          <li
            v-for="item in formatListItems(
              reportData.registrul_de_banca_neconcordante
            )"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
      </template>

      <template v-if="typeof soldBanca == 'number'">
        <p>
          Soldul de la bancă este în valoare de
          {{ soldBanca }} RON<span v-if="soldBancaDetaliat"
            >, {{ soldBancaDetaliat }}</span
          >.
        </p>
      </template>
      <template v-else>
        <p>LIPSEȘTE soldul de la bancă !!!</p>
      </template>

      <!-- ---------------ACTIV/PASIV ------------------ -->

      <p class="section-title">Situația de Activ/Pasiv:</p>

      <template v-if="reportData.primit_activ_pasiv == 'false'">
        <p><strong>Nu am primit Situația Activ/Pasiv!!!</strong></p>
      </template>

      <template v-else>
        <p>
          Totalul elementelor de Activ este în valoare de:
          {{
            typeof reportData.activ_pasiv_total_activ == "number"
              ? reportData.activ_pasiv_total_activ
              : "___"
          }}
          RON.
        </p>
        <p>
          Totalul elementelor de Pasiv este în valoare de:
          {{
            typeof reportData.activ_pasiv_total_pasiv == "number"
              ? reportData.activ_pasiv_total_pasiv
              : "___"
          }}
        </p>

        <p>
          Soldul fondului de rulment este in valoare de:
          {{
            typeof reportData.activ_pasiv_sold_fond_rulment == "number"
              ? reportData.activ_pasiv_sold_fond_rulment
              : "___"
          }}
          RON.
        </p>

        <p>
          Soldul fondului de reparații este in valoare de:
          {{
            typeof reportData.activ_pasiv_sold_fond_reparatii == "number"
              ? reportData.activ_pasiv_sold_fond_reparatii
              : "___"
          }}
          RON.
        </p>

        <p>
          Soldul din Registrul de bancă la data emiterii Situației de
          {{
            typeof reportData.activ_pasiv_sold_banca == "number"
              ? reportData.activ_pasiv_sold_banca
              : "___"
          }}
          RON.
        </p>
        <p>
          Soldul din Registrul de casă la data emiterii Situației de Activ/Pasiv
          {{
            typeof reportData.activ_pasiv_sold_casa == "number"
              ? reportData.activ_pasiv_sold_casa
              : "___"
          }}
          RON.
        </p>

        <template v-if="situatiaActivePasiveEsteEchilibrata">
          <p>Situația de Activ/Pasiv este echilibrată.</p>
        </template>

        <template v-else>
          <p>
            <strong>Observații</strong> cu privire la situația de Activ/Pasiv:
          </p>
          <ul>
            <li
              v-for="item in formatListItems(
                reportData.activ_pasiv_neconcordante
              )"
              :key="item"
            >
              {{ item }}
            </li>
          </ul>
        </template>
      </template>
      <!-- ---------------STATUL DE SALARII ------------------ -->

      <p class="section-title">Ștatul de salarii:</p>
      <template v-if="reportData.asociatia_are_angajati == 'false'">
        <p>Asociația nu are angajați.</p>
      </template>

      <template v-else>
        <p v-if="reportData.primit_statul_de_plata !== 'true'">
          <strong>Nu am primit ștatul de plată!</strong>
        </p>
        <template
          v-if="reportData.statul_de_salarii_contributii_corecte == 'true'"
        >
          <p>Contribuțiile calculate pe ștatul de plată sunt corecte.</p>
        </template>

        <template v-else>
          <p>
            <strong>Observații</strong> cu privire la calculul contribuțiilor pe
            ștatul de plată:
          </p>
          <ul>
            <li
              v-for="item in formatListItems(
                reportData.statul_de_salarii_neconcordante_contributii
              )"
              :key="item"
            >
              {{ item }}
            </li>
          </ul>
        </template>

        <template v-if="este_luna_de_depunere_d112">
          <template
            v-if="reportData.statul_de_salarii_d112_este_corecta == 'true'"
          >
            <p>Declarația 112 este întocmită corespunzător.</p>
          </template>

          <template v-else>
            <p><strong>Observații</strong> cu privire la Declarația 112:</p>
            <ul>
              <li
                v-for="item in formatListItems(
                  reportData.statul_de_salarii_neconcordante_d112
                )"
                :key="item"
              >
                {{ item }}
              </li>
            </ul>
          </template>

          <template
            v-if="reportData.statul_de_salarii_recipisa_d112_este_ok == 'true'"
          >
            <p>
              Există recipisa de depunere fără erori de validare pentru
              Declaratia 112.
            </p>
          </template>

          <template v-else>
            <p>
              <strong>Nu am primit</strong> recipisa de depunere fără erori de
              validare pentru Declaratia 112.
            </p>
          </template>
        </template>

        <template v-else>
          <p>Asociația depune D112 trimestrial.</p>
        </template>
      </template>

      <!-- ---------------OBSERVATII GENERALE ------------------ -->
      <template
        v-if="
          reportData.observatii_generale &&
          reportData.observatii_generale.length > 0
        "
      >
        <p><strong>Observații:</strong></p>
        <ul>
          <li
            v-for="item in formatListItems(reportData.observatii_generale)"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
      </template>
      <!-- <p v-else>fara observatii generale:</p> -->

      <p style="margin-top: 18pt">
        Contabilitatea Asociatiei este organizata in partida simpla in
        conformitate cu prevederilor Legii Contabilitatii nr. 82/1999
        republicata cu modificarile si completarile ulterioare.
      </p>

      <div class="columns is-centered is-vcentered">
        <div class="column">
          <img
            src="https://cenzor-9f808.web.app/stamp.jpeg"
            alt="stamp"
            width="84pt"
            style="margin: 30pt; display: inline"
          />
        </div>
        <div class="column">
          <p style="display: inline">
            <strong>Cenzor, {{ reportDate }}</strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
// import roLocale from "date-fns/locale/ro";

export default {
  name: "ComptrollerReportV5",

  props: {
    data: Object,
    client: Object,
    formVersion: String,
  },

  data() {
    return {
      reportUrl: null,
      isGeneratingReport: false,
      // the version of the report component
      // must match the version of the form used to collect the report data
      reportVersion: "5",
    };
  },

  computed: {
    reportData() {
      return this.data || {};
    },
    clientData() {
      return this.client || {};
    },
    reportTitle() {
      return "RAPORT LUNAR DE VERIFICARE AL CENZORULUI";
    },
    reportSubtitle() {
      return `pentru ${this.clientData.name_official}`;
    },
    reportDate() {
      return format(new Date(), "yyyy-MM-dd");
    },

    errors() {
      let requiredClientProperties = ["name_official", "address"];
      let requiredReportProperties = [
        "report_year",
        "report_month",
        "asociatia_exclusiv_banca",
        "popriri_conturi",
        "primit_registrul_de_casa",
        "primit_statul_de_plata",
        "asociatia_are_angajati",
        "repartizarea_facturilor_suma_facturilor_repartizate",
        "repartizarea_facturilor_total_lunar_lista_de_plata",
        "repartizarea_facturilor_facturile_din_liste_sunt_corecte",

        "registrul_de_banca_vs_extras_cont_este_ok",
        "registrul_de_banca_sold_cont_curent",
        "registrul_de_banca_sold_cont_colector",
        "registrul_de_banca_sold_cont_depozite",
        "registrul_de_banca_sold_initial",

        // "activ_pasiv_neconcordante",
      ];

      // fields required depending on other fields
      if (
        (!this.reportData ||
          !this.reportData.asociatia_exclusiv_banca ||
          this.reportData.asociatia_exclusiv_banca !== "true") &&
        (!this.reportData.asociatia_operatiuni_cash_luna_raportului ||
          this.reportData.asociatia_operatiuni_cash_luna_raportului !== "false")
      ) {
        requiredReportProperties.push(
          "registrul_de_casa_incasarile_sunt_corecte",
          "registrul_de_casa_platile_sunt_corecte",
          "registrul_de_casa_este_corect_pe_zile",
          "registrul_de_casa_sold_casa",
          "registrul_de_casa_nr_prima_chitanta",
          "registrul_de_casa_nr_ultima_chitanta",
          "registrul_de_casa_sold_casa_initial"
        );
      }

      if (
        !this.reportData ||
        !this.reportData.asociatia_are_angajati ||
        this.reportData.asociatia_are_angajati !== "false"
      ) {
        requiredReportProperties.push("statul_de_salarii_contributii_corecte");

        if (this.este_luna_de_depunere_d112) {
          requiredReportProperties.push(
            "statul_de_salarii_d112_este_corecta",
            "statul_de_salarii_recipisa_d112_este_ok"
          );
        }
      }

      if (
        !this.reportData ||
        !this.reportData.primit_activ_pasiv ||
        this.reportData.primit_activ_pasiv == "true"
      ) {
        requiredReportProperties.push(
          "activ_pasiv_total_activ",
          "activ_pasiv_total_pasiv",
          "activ_pasiv_sold_casa",
          "activ_pasiv_sold_fond_rulment",
          "activ_pasiv_sold_fond_reparatii",
          "activ_pasiv_sold_banca"
        );
      }

      if (
        !this.reportData ||
        !this.reportData.facturi_achitate_fonduri ||
        this.reportData.facturi_achitate_fonduri !== "false"
      ) {
        requiredReportProperties.push(
          "repartizarea_facturilor_facturile_din_fonduri_sunt_corecte"
        );
      }

      // requiredClientProperties.filter(prop => this.clientData[prop] === null);
      let errors = [].concat(
        requiredClientProperties
          .filter(
            (prop) => !Object.prototype.hasOwnProperty.call(this.client, prop)
          )
          .map((err) => `Eroare: client: lipseste campul ${err}`),
        requiredReportProperties
          .filter(
            (prop) => !Object.prototype.hasOwnProperty.call(this.data, prop)
          )
          .map((err) => `Eroare: raport: lipseste campul ${err}`)
      );

      if (this.reportVersion !== this.formVersion) {
        errors.push(
          [
            "Atenție: raportul este v",
            this.reportVersion,
            " dar formularul este v",
            this.formVersion,
          ].join("")
        );
      }
      return errors;
    },

    reportPeriod() {
      if (
        !this.reportData ||
        !this.reportData.report_year ||
        !this.reportData.report_month
      )
        return "DATA LIPSA";

      const months = {
        1: "Ianuarie",
        2: "Februarie",
        3: "Martie",
        4: "Aprilie",
        5: "Mai",
        6: "Iunie",
        7: "Iulie",
        8: "August",
        9: "Septembrie",
        10: "Octombrie",
        11: "Noiembrie",
        12: "Decembrie",
      };
      return [
        months[this.reportData.report_month],
        this.reportData.report_year,
      ].join(" ");
    },

    sumaCotelorVsSumaFacturilorRepartizateEsteOk() {
      return (
        Math.abs(
          this.reportData.repartizarea_facturilor_suma_facturilor_repartizate -
            this.reportData.repartizarea_facturilor_total_lunar_lista_de_plata
        ) < 2
      );
    },

    situatiaActivePasiveEsteEchilibrata() {
      return (
        Math.abs(
          this.reportData.activ_pasiv_total_activ -
            this.reportData.activ_pasiv_total_pasiv
        ) < 2
      );
    },

    soldBanca() {
      return (
        this.reportData.registrul_de_banca_sold_cont_curent +
        this.reportData.registrul_de_banca_sold_cont_colector +
        this.reportData.registrul_de_banca_sold_cont_depozite
      );
    },

    soldBancaDetaliat() {
      if (
        !this.reportData.registrul_de_banca_sold_cont_colector &&
        !this.reportData.registrul_de_banca_sold_cont_depozite
      )
        return null;

      let texts = [];
      if (this.reportData.registrul_de_banca_sold_cont_curent) {
        texts.push(
          [
            "format din:",
            this.reportData.registrul_de_banca_sold_cont_curent,
            "RON cont curent",
          ].join(" ")
        );
      }

      if (this.reportData.registrul_de_banca_sold_cont_colector) {
        texts.push(
          [
            this.reportData.registrul_de_banca_sold_cont_colector,
            "RON cont colector",
          ].join(" ")
        );
      }

      if (this.reportData.registrul_de_banca_sold_cont_depozite) {
        texts.push(
          [
            this.reportData.registrul_de_banca_sold_cont_depozite,
            "RON depozite",
          ].join(" ")
        );
      }
      return texts.join(", ");
    },

    diferentaSoldRegCasaVsActivPasiv() {
      if (
        !this.reportData ||
        this.reportData.registrul_de_casa_sold_casa === null ||
        this.reportData.activ_pasiv_sold_casa === null
      )
        return null;
      return (
        this.reportData.registrul_de_casa_sold_casa -
        this.reportData.activ_pasiv_sold_casa
      );
    },

    este_luna_de_depunere_d112() {
      console.log("luna:", this.client.d112_frequency, this.data.report_month);
      if ("d112_frequency" in this.client) {
        if (this.client.d112_frequency == "trimestrial") {
          if (
            [1, 2, 4, 5, 7, 8, 10, 11].includes(
              parseInt(this.data.report_month)
            )
          ) {
            console.log("D112 NU");
            return false;
          } else {
            console.log("D112 DA");
          }
        }
      }

      return true;
    },
  },
  methods: {
    formatListItems(text) {
      return (text || "lipsa observatii !!!!").trim().split("\n");
    },
  },
};
</script>

<style scoped lang="css">
.content p {
  text-indent: 36pt;
  margin-bottom: 0px;
}

.content ul {
  margin: 0pt 36pt;
  /* display: list-item;
  list-style-type: disc;
  list-style-position: inside; */
}

.content li {
  text-indent: 0pt;
  list-style-type: "-";
  list-style-position: inside;
}

.section-title {
  font-weight: bold;
  margin-top: 10pt;
}
</style>