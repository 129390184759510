var render = function render(){
  var _vm$client$title, _vm$client$name_offic, _vm$client$address, _vm$client$d112_frequ, _vm$client$contract_s, _vm$client$first_mont, _vm$client$contract_s2, _vm$client$updated_at, _vm$client$comptrolle;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-loading', {
    attrs: {
      "is-full-page": _vm.isFullPage,
      "active": !_vm.client,
      "can-cancel": false
    }
  }), !!_vm.client ? _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "columns is-centered"
  }, [_c('div', {
    staticClass: "column"
  }, [_c('div', [_c('h1', {
    staticClass: "title is-size-2"
  }, [_vm._v(_vm._s((_vm$client$title = _vm.client.title) !== null && _vm$client$title !== void 0 ? _vm$client$title : "Client fara nume"))]), _c('h3', [_vm._v(_vm._s((_vm$client$name_offic = _vm.client.name_official) !== null && _vm$client$name_offic !== void 0 ? _vm$client$name_offic : ""))]), _c('h3', {
    staticClass: "pb-3"
  }, [_vm._v(_vm._s((_vm$client$address = _vm.client.address) !== null && _vm$client$address !== void 0 ? _vm$client$address : ""))]), _c('h3', {
    staticClass: "pb-3"
  }, [_vm._v("Depune D112: " + _vm._s((_vm$client$d112_frequ = _vm.client.d112_frequency) !== null && _vm$client$d112_frequ !== void 0 ? _vm$client$d112_frequ : ""))]), _c('h3', [_vm._v("Data contractului: " + _vm._s((_vm$client$contract_s = _vm.client.contract_start_date) !== null && _vm$client$contract_s !== void 0 ? _vm$client$contract_s : ""))]), _c('h3', [_vm._v("Prima luna: " + _vm._s((_vm$client$first_mont = _vm.client.first_month) !== null && _vm$client$first_mont !== void 0 ? _vm$client$first_mont : ""))]), _c('h3', [_vm._v("Data stop a contractului: " + _vm._s((_vm$client$contract_s2 = _vm.client.contract_stop_date) !== null && _vm$client$contract_s2 !== void 0 ? _vm$client$contract_s2 : ""))]), _c('h3', [_vm._v("ultima actualizare: " + _vm._s(_vm._f("dateFormat")((_vm$client$updated_at = _vm.client.updated_at) === null || _vm$client$updated_at === void 0 ? void 0 : _vm$client$updated_at.toDate())))]), _c('h3', [_vm._v("Entitate Cenzor: " + _vm._s((_vm$client$comptrolle = _vm.client.comptroller_entity) !== null && _vm$client$comptrolle !== void 0 ? _vm$client$comptrolle : ""))])])])])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }