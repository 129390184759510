import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'


function initFirebase() {
  firebase.initializeApp(JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG))
  // firebase.firestore().settings({
  //   host: "localhost:8090",
  //   ssl: false
  // })
  return new Promise((resolve, reject) => {
    firebase.firestore().enablePersistence({
        synchronizeTabs: true
      })
      .then(resolve)
      .catch(err => {
        if (err.code === 'failed-precondition') {
          console.log("ERROR:  Multiple tabs open, persistence can only be enabled in one tab at a a time.")
          reject(err)
          // Multiple tabs open, persistence can only be
          // enabled in one tab at a a time.
        } else if (err.code === 'unimplemented') {
          console.log("ERROR: // The current browser does not support all of the features required to enable persistence")
          reject(err)
          // The current browser does not support all of
          // the features required to enable persistence
        }
      })
  })
}

export {
  firebase,
  initFirebase
}
