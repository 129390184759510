var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-table', {
    attrs: {
      "data": _vm.localReports,
      "debounce-search": 300,
      "sort-icon": "arrow-up",
      "sort-icon-size": "sortIconSize",
      "default-sort": "perioada"
    }
  }, [_c('b-table-column', {
    attrs: {
      "field": "perioada",
      "label": "perioada",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (props) {
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'ViewReport',
              params: {
                reportId: props.row.id
              }
            }
          }
        }, [_vm._v(_vm._s(_vm.buildReportTitle(props.row.data)))])];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "field": "created_at",
      "label": "creat",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (props) {
        return [_vm._v(_vm._s(_vm._f("dateFormat")(props.row.created_at)))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "field": "updated_at",
      "label": "modificat",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (props) {
        return [_vm._v(_vm._s(_vm._f("dateFormat")(props.row.updated_at)))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "field": "version",
      "label": "versiune",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (props) {
        return [_vm._v(_vm._s(props.row.formVersionString || "4"))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "label": "actiuni"
    },
    scopedSlots: _vm._u([{
      key: "searchable",
      fn: function (props) {
        return [_c('b-input', {
          attrs: {
            "placeholder": "Search...",
            "icon": "magnify",
            "size": "is-small"
          },
          model: {
            value: props.filters.address,
            callback: function ($$v) {
              _vm.$set(props.filters, "address", $$v);
            },
            expression: "props.filters.address"
          }
        })];
      }
    }, {
      key: "default",
      fn: function (props) {
        return [_c('div', {
          staticClass: "buttons"
        }, [_c('b-button', {
          staticClass: "is-danger is-outlined",
          attrs: {
            "icon-left": "trash"
          },
          on: {
            "click": function ($event) {
              return _vm.confirmAndDeleteReport(props.row.id);
            }
          }
        }, [_vm._v("Delete")]), props.row.storagePath ? _c('b-button', {
          staticClass: "is-success",
          attrs: {
            "icon-left": "download"
          },
          on: {
            "click": function ($event) {
              return _vm.downloadReport(props.row);
            }
          }
        }, [_vm._v("Descarcă")]) : _vm._e()], 1)];
      }
    }])
  }), _c('template', {
    slot: "empty"
  }, [_c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "content has-text-grey has-text-centered"
  }, [_c('p', [_c('b-icon', {
    attrs: {
      "icon": "emoticon-sad",
      "size": "is-large"
    }
  })], 1), _c('p', [_vm._v("Nothing here.")])])])])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }