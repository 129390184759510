<template>
  <div>
    <p>Versiune raport lipsă: V{{ formVersion }}</p>
  </div>
</template>

<script>
// import { format, parseISO } from "date-fns";
// import roLocale from "date-fns/locale/ro";

export default {
  name: "ComptrollerReportMissing",

  props: {
    data: Object,
    client: Object,
    formVersion: String,
  },

  data() {
    return {
      reportUrl: null,
      isGeneratingReport: false,
      // the version of the report component
      // must match the versio of the form used to collect the report data
    };
  },

  computed: {
    reportData() {
      return this.data || {};
    },
    clientData() {
      return this.client || {};
    },
  },
};
</script>

<style scoped lang="css">
</style>