<template>
  <div>
    <survey-component :survey="surveyModel"></survey-component>
  </div>
</template>

<script>
var SurveyVue;
// import 'survey-vue/survey.css'
import "survey-vue/modern.css";

SurveyVue = require("survey-vue");
SurveyVue.StylesManager.applyTheme("modern");
SurveyComponent = SurveyVue.Survey;

var SurveyComponent;

export default {
  name: "x-survey",
  // mixins: [segmentMixin],
  components: {
    SurveyComponent,
  },

  data: function () {
    return {
      // the model that surveyJS will display
      surveyModel: null,
    };
  },

  props: {
    content: {
      type: Object,
    },

    // readOnly = true => survey is ONLY for display, NOT ACTIVE
    readOnly: { type: Boolean, default: false },

    data: { type: Object },

    selectedPage: { type: String, default: null },
  },

  watch: {
    "$props.data": {
      handler() {
        this.prepareSurveyModel();
      },
      deep: true,
      immediate: true,
    },
    "$props.selectedPage": {
      handler() {
        this.surveyModel.currentPage = this.$props.selectedPage;
      },
    },
    "$props.readOnly": {
      handler(newVal) {
        console.log("readonly change:", newVal);
        this.surveyModel.mode = newVal ? "display" : "edit";
      },
    },
  },

  methods: {
    prepareSurveyModel() {
      const sanitizedContent = this.sanitizeContent(this.content);
      this.surveyModel = new SurveyVue.Model(sanitizedContent);
      this.surveyModel.mode = this.readOnly ? "display" : "edit";
      this.surveyModel.data = this.data;
      this.surveyModel.onComplete.add((results) =>
        this.$emit("completed", results)
      );
      this.surveyModel.onValueChanged.add((results) =>
        this.$emit("update", results)
      );
      this.surveyModel.onCurrentPageChanging.add((survey, options) =>
        this.$emit("activePageChange", options.newCurrentPage.name)
      );
      this.surveyModel.showPrevButton = true;
      this.surveyModel.pageNextText = ">>";
      this.surveyModel.pagePrevText = "<<";
      this.surveyModel.completeText = "save";
      this.surveyModel.showProgressBar = "top";
      this.surveyModel.showPageTitles = true;
      // this.surveyModel.isSinglePage = true;
      this.surveyModel.showCompletedPage = false;

      this.surveyModel.checkErrorsMode = "onComplete";
      //onHidden - clear the question value when it becomes invisible. If a question has value and it was invisible initially then survey clears the value on completing.
      this.surveyModel.clearInvisibleValues = "onHidden";
      // this.surveyModel.goNextPageAutomatic = true;

      //
      // singlePage - combine all survey pages in a single page. Pages will be converted to panels.

      // questionPerPage - show one question per page. Survey will create a separate page for every question.
      //  standard
      this.surveyModel.questionsOnPageMode = "standard";

      this.surveyModel.showQuestionNumbers = "off";
      // jsonErrors;
      // sendResultOnPageNext;
      // showInvisibleElements;
    },

    reset() {
      this.prepareSurveyModel();
    },
    sanitizeContent(content) {
      // console.log(JSON.stringify(content))
      let result = Object.assign({}, content);
      // result.pages = content.pages.map((page) => ({
      //   elements: page.elements
      //     .map((e) => (e.type == 'matrix' ? null : e))
      //     .filter((e) => !!e)
      // }))

      // console.log(JSON.stringify(result))
      return result;
    },
  },
};
</script>
<style>
</style>
