

import yearlyFormJson8 from "@/models/yearlyFormV8.json"

const formVersions = {

  "8": yearlyFormJson8
}

const latestFormVersionString = Object.keys(formVersions)
  .sort((a, b) => parseInt(a) > parseInt(b))
  .pop();

const latestForm = formVersions[latestFormVersionString];

export default {
  newYearlyReport(data, client, formVersion=null) {

    // build a date that points to first day of previous month
    let defaultDate = new Date();

    const pastYear = defaultDate.getFullYear() - 1;
    defaultDate.setDate(0); // 0 will result in the last day of the previous month
    defaultDate.setDate(1); // 1 will result in the first day of the month

    const newReport = {};
    newReport.data = JSON.parse(JSON.stringify(data || {
      report_year: pastYear,
      report_month: defaultDate.getMonth() + 1 // +1 because getMonth is 0-indexed
    }, null, 3))
    newReport.client = JSON.parse(JSON.stringify(client || null, null, 3))

    // store the html of the generated report
    newReport.html = null

    // set formVersionString to latest available form version
    newReport.formVersionString = formVersion || latestFormVersionString;


    return newReport
  },

  reportFilename(report) {
    const filename = [
      "raport_cenzor_",
      report.client.title.split(/[^\w]/).join("_"),
      "_",
      report.data.report_year,
      ".docx",
    ].join("");

    return filename;
  },


  //a function that returns the desired form version
  getFormByVersionString: (versionString) => formVersions[versionString],

  // the latest form
  currentForm: latestForm,

  // the latest form version number
  currentFormVersionString: latestFormVersionString

}