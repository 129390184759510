<template>
  <div>
    <div v-if="showChecks">
      <pre>
        {{reportData}}
      </pre>
    </div>
    <div v-if="showChecks" id="report-checks">
      <!-- ---------------VERIFICARI ------------------ -->

      <p>Verificări:</p>
      <p class="has-text-grey">versiune formular: {{ formVersion }}</p>
      <p class="has-text-grey">versiune raport: {{ reportVersion }}</p>
      <p
        v-for="err in missingFormDataErrors"
        v-bind:key="err"
        class="has-text-danger"
      >
        {{ err }}
      </p>

      <p
        v-for="err in missingPreviousMonthDataErrors"
        v-bind:key="err"
        class="has-text-danger"
      >
        {{ err }}
      </p>

      <!-- ---------------VERIFICARE LEGATURA CU RAPORT PT LUNA TRECUTA ------------------ -->
      <p v-if="monthlyReportsData.length" class="has-text-danger">asdf</p>
    </div>

    <!-- START PREZENTARE RAPORT -->
    <div id="report-content" >
      <img src="https://cenzor-9f808.web.app/logo.jpeg" alt="logo" />
      <h1 class="has-text-centered">{{ reportTitle }}</h1>
      <h2 class="has-text-centered">{{ reportSubtitle }}</h2>

      <h3 class="has-text-centered">
        {{ reportPeriod }}
      </h3>

      <p>
        <strong>Comptroller SRL</strong>, reprezentată prin Mareș Mihaela, în
        calitate de cenzor pentru
        <strong>{{ clientData.name_official || "________________" }}</strong
        >, cu sediul în
        <strong>{{ clientData.address || "________________" }}</strong
        >, am analizat și verificat documentele care stau la baza întocmirii
        rapoartelor și listelor de plată pentru anul
        <strong>{{ reportPeriod || "________________" }}</strong
        >, conform cu Legea nr. 196/2018 privind înfiinţarea, organizarea şi
        funcţionarea Asociaţiilor de Proprietari.
      </p>

      <p>
        <strong>Comptroller SRL</strong> a incheiat un contract de prestari servicii 
        cu <strong>{{ clientData.name_official || "________________" }}</strong> 
        in data de {{ clientData.contract_start_date || "_________"}} 
        si a preluat verificarea incepand cu listele lunii 
        {{ clientData.first_month || "__________"}}.
         
      </p>

      <p class="section-title">Pentru verificarea documentelor s-au fixat următoarele obiective:</p>
 
      <p>
        <ul>
          <li>
              Constatat egalitatea dintre suma cotelor apartamentelor și suma facturilor repartizate în listă; specificat unde există neconcordanțe
          </li>
          <li>
              Verificat corectitudinea înregistrării chitanțelor și încasărilor prin bancă (datele și sume corecte) în Registrul de Casă/Jurnal/Bancă; specificat unde există neconcordanțe
          </li>
          <li>
              Verificat corectitudinea înregistrării plăților (cronologie și sume corecte) în Registrul de Casă/Bancă; specificat unde există neconcordanțe
          </li>
          <li>
              Verificat corelarea dintre plațile prin bancă cu facturile corespunzătoare; specificat unde există neconcordanțe
          </li>
          <li>
              Verificat dacă fiecare plată din Registrul Fondului are o factură corespunzătoare; specificat unde există neconcordanțe
          </li>
          <li>
            Verificat corectitudinea totalului zilnic, totalului de plăți si de încasări trecute în Registrul de casă; specificat unde există neconcordanțe
          </li>
          <li>
            Verificat valoarea soldului de la casă și recomandat depunerea în bancă atunci cand este cazul
          </li>
          <li>
            Verificat în raportul lunar al administratorului corectitudinea totalul plăților făcute din fonduri si totalul plăților făcute din cote
          </li>
          <li>
            Constatat echilibrul din Situația de Activ-Pasiv 
          </li>
          <li>
            Verificat corectitudinea contribuțiilor calculate pe Ștatul de plată; specificat unde există neconcordanțe
          </li>
          <li>
            Verificat depunerea fără erori de validare a Recipisei pentru Declarația 112
          </li>
          <li>
            Certificarea listei si celorlalte documente contabile
          </li>
          <li>
            Verificarea modificarilor fata de luna trecuta legat de numarul de persoane si verificarea existentei cererilor de adaugare/scoatere de la intretinere
          </li>
          <li>
            Verificarea corectitudinii calcului penalizarilor
          </li>
          <li>
            Verificarea hotararilor asociatiei si punerea lor in practica
          </li>
          <li>
            Verificarea modului de utilizare a fondurilor asociatiei si existenta aprobarilor;
          </li>
          <li>
            Verificarea modului de respectare a prevederilor Legii nr.196/2018 privind înfiinţarea, organizarea şi funcţionarea asociaţiilor de proprietari
          </li>
        </ul>
      </p>

      <!-- ---------------NEREGULARITATI ------------------ -->
      <template v-if="if_neregularitati || warnings.length">
        <p class="section-title">Neregularitati</p>
        <p>
          În urma controlului efectuat s-au constatat următoarele aspecte: 
        </p>
        <div v-for="warning in warnings" :key="warning.report_month">
          <p><strong>Raport luna {{warning.report_month}} {{reportPeriod}}</strong></p>
          <p v-for="message in warning.messages" :key="message">{{message}}</p>

        </div>
      </template>
 
      <template v-else>
        
          <p class="section-title">
            Modul de repartizare a cheltuielilor asociaţiei în listele de întreţinere
          </p>

          <p>
          Din verificarea listelor de plata a cotelor de contributie la cheltuielile 
          asociatiei s-a constatatat ca au fost respectate prevederile din Legea 
          nr.196/2018 cu privire la repartizarea cheltuielilor asociaţiei în lista 
          lunară de plată. 
        </p>
        </template>

      <!-- --------------- VERIFICARI ------------------ -->


      <p class="section-title">La data de 31.12.{{reportPeriod}} regasim urmatoarele solduri:</p>

        <ul>
          <li>
            Soldul in casa este in valoare de {{this.reportData.anual_sold_casa}} RON. 
          </li>
          <li>
            Soldul in banca este in valoare  de {{this.reportData.anual_sold_banca}} RON.
          </li>
          <li>
            Sumele neachitate de proprietarii din asociatie pentru lista de plata curenta sunt in valoare de {{this.reportData.anual_valoare_sume_neachitate_lista_curenta}} RON.
          </li>
          <li>
            Restantele existe la 31.12.{{reportPeriod}} sunt in valoare de {{this.reportData.anual_valoare_restante}} RON.
          </li>
          <li>
            Soldul Fondului de Rulment este in valoare de {{this.reportData.anual_sold_fond_rulment}} RON.
          </li>
          <li>
            Soldul Fondului de Reparatii este in valoare de {{this.reportData.anual_sold_fond_reparatii}} RON.
          </li>
          <li>
            Soldul altor fonduri legal stabilite este in valoare de {{this.reportData.anual_sold_alte_fonduri}} RON.
          </li>
          <li>
            Soldul facturilor neachitate este in valoare de {{this.reportData.anual_valoare_furnizori_facturi_neachitate}} RON.
          </li>
        </ul>


        <template v-if="parseInt(this.reportData.anual_sold_casa) > 1000  ">
           <p class="section-title">La data de 31.12.{{reportPeriod}} regasim urmatoarele solduri:</p>
              <p>
                Soldul de la casă este mai mare decât plafonul prevăzut de lege,
                în valoare de 1000 de RON;
                <strong>recomandăm</strong> depunerea diferenței în contul de la
                bancă.
              </p>
            </template>
        
     
        <img src="https://cenzor-9f808.web.app/stamp.jpeg" alt="stamp" 
          style="margin: 2rem 6rem; display: inline; float: left;" width="84pt">
        <p style="text-align: right;margin: 4rem;">
          <strong data-v-429cc202="">Cenzor, {{reportDate}}</strong>
        </p>

      
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
// import currency from "currency.js";
// import roLocale from "date-fns/locale/ro";

export default {
  name: "ComptrollerYearlyReportV8",

  props: {
    data: Object,
    client: Object,
    formVersion: String,
    monthlyReportsData: { type: Array, default: () => [] },
    showChecks: { type: Boolean, default: true },
  },

  data() {
    return {
      reportUrl: null,
      // the version of the report component
      // must match the version of the form used to collect the report data
      reportVersion: "8",
    };
  },

  methods: {
    formatListItems(text) {
      return (text || "lipsa observatii !!!!").trim().split("\n");
    },
  },

  computed: {
    reportData() {
      return this.data || {};
    },
    clientData() {
      return this.client || {};
    },
    reportTitle() {
      return "RAPORT ANUAL DE VERIFICARE AL CENZORULUI";
    },
    reportSubtitle() {
      return `pentru ${this.clientData.name_official}`;
    },
    reportDate() {
      return format(new Date(), "yyyy-MM-dd");
    },

    missingFormDataErrors() {
      let requiredClientProperties = ["name_official", "address"];
      let requiredReportProperties = [
        "report_year",
        "report_month",
          "anual_sold_alte_fonduri",
          "report_month",
          "anual_valoare_restante",
          "anual_sold_banca",
          "anual_sold_casa",
          "report_year",
          "anual_valoare_furnizori_facturi_neachitate",
          "anual_valoare_sume_neachitate_lista_curenta",
          "anual_sold_fond_reparatii",
          "anual_sold_fond_rulment"
      ];

     

      // requiredClientProperties.filter(prop => this.clientData[prop] === null);
      let errors = [].concat(
        requiredClientProperties
          .filter(
            (prop) => !Object.prototype.hasOwnProperty.call(this.client, prop)
          )
          .map((err) => `Eroare: client: lipseste campul ${err}`),
        requiredReportProperties
          .filter(
            (prop) => !Object.prototype.hasOwnProperty.call(this.data, prop)
          )
          .map((err) => `Eroare: raport: lipseste campul ${err}`)
      );

      if (this.reportVersion !== this.formVersion) {
        errors.push(
          [
            "Atenție: raportul este v",
            this.reportVersion,
            " dar formularul este v",
            this.formVersion,
          ].join("")
        );
      }
      return errors;
    },

    missingPreviousMonthDataErrors() {
      // if previousMonthData is null, this means there is no report for the previous month
      // ie: this is the first time we generete a report for this client,
      // so no need to check previous month form data
      if (!this.monthlyReportsData) {
        return [];
      }

      

   
      return [];
    },

    warnings(){

      let warnings = [];
      const interestingFields = [
          'registrul_de_banca_neconcordante',
          'activ_pasiv_neconcordante',
          'statul_de_salarii_neconcordante_contributii',
          'statul_de_salarii_neconcordante_d112',
          'repartizarea_facturilor_neconcordante_sume',
          'repartizarea_facturilor_neconcordante_facturi_din_liste',
          'repartizarea_facturilor_neconcordante_facturi_din_fonduri',
          'repartizarea_facturilor_neconcordante_sume',
          'registrul_de_casa_neconcordante_incasari',
          'registrul_de_casa_neconcordante_plati',
          'registrul_de_casa_neconcordante_pe_zile',
          'observatii_generale'
        ]
      

      for (const report of this.monthlyReportsData){
        
        let messages=[]  
        
        for( const field of interestingFields){
          if (report.data.hasOwnProperty(field) && report.data[field] !=null)
          messages.push(report.data[field])
        }

        if (messages.length){
          warnings.push({'report_month':report.data.report_month, messages:messages})
        }
     
      }

      warnings.sort((firstEl, secondEl)=>firstEl.report_month - secondEl.report_month)      
      return warnings;
    },

    reportPeriod() {
      if (
        !this.reportData ||
        !this.reportData.report_year 
      )
        return "DATA LIPSA";

      return this.reportData.report_year;
    },

    if_neregularitati(){
      return false;
    }

  },

};
</script>

<style scoped lang="css">
        
@import '../../assets/variables.css';        
        
        html {
          /* Set the font globally */
          /* Set sans-serif & mono fonts */
          --sans-font: -apple-system, BlinkMacSystemFont, "Avenir Next", Avenir,
            "Nimbus Sans L", Roboto, Noto, "Segoe UI", Arial, Helvetica,
            "Helvetica Neue", sans-serif;
          --mono-font: Consolas, Menlo, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
        
          /* Body font size. By default, effectively 18.4px, based on 16px as 'root em' */
          --base-fontsize: 1rem;
        
          /* Major third scale progression - see https://type-scale.com/ */
          --header-scale: 1.2;
        
          /* Line height is set to the "Golden ratio" for optimal legibility */
          --line-height: 1.618;
        
          /* Default (light) theme */
          --bg: #fff;
          --accent-bg: #f5f7ff;
          --text: #212121;
          --text-light: #585858;
          --border: #d8dae1;
          --accent: #0d47a1;
          --accent-light: #90caf9;
          --code: #d81b60;
          --preformatted: #444;
          --marked: #ffdd33;
          --disabled: #efefef;
        }
        
        /* Make the body a nice central block */
        body {
          color: var(--text);
          background: var(--bg);
          font-size: var(--base-fontsize);
          line-height: var(--line-height);
          display: flex;
          min-height: 100vh;
          flex-direction: column;
          flex: 1;
          margin: 0 auto;
          max-width: 45rem;
          padding: 0 0.5rem;
          overflow-x: hidden;
          word-break: break-word;
          overflow-wrap: break-word;


          --base-fontsize: 1rem;
        
          /* Major third scale progression - see https://type-scale.com/ */
          --header-scale: 1.2;
        
          /* Line height is set to the "Golden ratio" for optimal legibility */
          --line-height: 1.618;
        }
        
        /* Make the header bg full width, but the content inline with body */
        header {
          background: var(--accent-bg);
          border-bottom: 1px solid var(--border);
          text-align: center;
          padding: 2rem 0.5rem;
          width: 100vw;
          position: relative;
          box-sizing: border-box;
          left: 50%;
          right: 50%;
          margin-left: -50vw;
          margin-right: -50vw;
        }
        
        /* Remove margins for header text */
        header h1,
        header p {
          margin: 0;
        }
        
        /* Add a little padding to ensure spacing is correct between content and nav */
        main {
          padding-top: 1.5rem;
        }
        
        /* Fix line height when title wraps */
        h1,
        h2,
        h3 {
          line-height: 1.1;
        }

        p {
            text-indent: 36pt;
            text-align: justify;
        }
        
        
        footer {
          margin-top: 4rem;
          padding: 2rem 1rem 1.5rem 1rem;
          color: var(--text-light);
          font-size: 0.9rem;
          text-align: center;
          border-top: 1px solid var(--border);
        }
        
        /* Format headers */
        h1 {
          font-size: calc(
            var(--base-fontsize) * var(--header-scale) * var(--header-scale) *
              var(--header-scale) * var(--header-scale)
          );
          margin-top: calc(var(--line-height) * 1.5rem);
        }
        
        h2 {
          font-size: calc(
            var(--base-fontsize) * var(--header-scale) * var(--header-scale) *
              var(--header-scale)
          );
          margin-top: calc(var(--line-height) * 1.5rem);
        }
        
        h3 {
          font-size: calc(
            var(--base-fontsize) * var(--header-scale) * var(--header-scale)
          );
          margin-top: calc(var(--line-height) * 1.5rem);
          margin-bottom: calc(var(--line-height) * 1.5rem);
          line-height: 1.1;
        }
        
        h4 {
          font-size: calc(var(--base-fontsize) * var(--header-scale));
          margin-top: calc(var(--line-height) * 1.5rem);
        }
        
        h5 {
          font-size: var(--base-fontsize);
          margin-top: calc(var(--line-height) * 1.5rem);
        }
        
        h6 {
          font-size: calc(var(--base-fontsize) / var(--header-scale));
          margin-top: calc(var(--line-height) * 1.5rem);
        }
        
        /* Format links & buttons */
        a,
        a:visited {
          color: var(--accent);
        }
        
        a:hover {
          text-decoration: none;
        }
        
       
        /* Format the expanding box */
        details {
          background: var(--accent-bg);
          border: 1px solid var(--border);
          border-radius: 5px;
          margin-bottom: 1rem;
        }
        
        summary {
          cursor: pointer;
          font-weight: bold;
          padding: 0.6rem 1rem;
        }
        
        details[open] {
          padding: 0.6rem 1rem 0.75rem 1rem;
        }
        
        details[open] summary {
          margin-bottom: 0.5rem;
          padding: 0;
        }
        
        details[open] > *:last-child {
          margin-bottom: 0;
        }
        
        /* Format tables */
        table {
          border-collapse: collapse;
          width: 100%;
          margin: 1.5rem 0;
        }
        
        td,
        th {
          border: 1px solid var(--border);
          text-align: left;
          padding: 0.5rem;
        }
        
        th {
          background: var(--accent-bg);
          font-weight: bold;
        }
        
        tr:nth-child(even) {
          /* Set every other cell slightly darker. Improves readability. */
          background: var(--accent-bg);
        }
        
        table caption {
          font-weight: bold;
          margin-bottom: 0.5rem;
        }
        
        /* Lists */
        ol,
        ul {
          padding-left: 4rem;
          text-align: justify;
          text-indent: 0;
          list-style: disc;

        }
        
        
        /* Misc body elements */
        
        hr {
          color: var(--border);
          border-top: 1px;
          margin: 1rem auto;
        }
        
        mark {
          padding: 2px 5px;
          border-radius: 4px;
          background: var(--marked);
        }
        
        main img,
        main video {
          max-width: 100%;
          height: auto;
          border-radius: 5px;
        }
        
        figure {
          margin: 0;
        }
        
        figcaption {
          font-size: 0.9rem;
          color: var(--text-light);
          text-align: center;
          margin-bottom: 1rem;
        }
        
        blockquote {
          margin: 2rem 0 2rem 2rem;
          padding: 0.4rem 0.8rem;
          border-left: 0.35rem solid var(--accent);
          opacity: 0.8;
          font-style: italic;
        }
        
        cite {
          font-size: 0.9rem;
          color: var(--text-light);
          font-style: normal;
        }
        
        /* Use mono font for code like elements */
        code,
        pre,
        pre span,
        kbd,
        samp {
          font-size: 1.075rem;
          font-family: var(--mono-font);
          color: var(--code);
        }
        
        kbd {
          color: var(--preformatted);
          border: 1px solid var(--preformatted);
          border-bottom: 3px solid var(--preformatted);
          border-radius: 5px;
          padding: 0.1rem;
        }
        
        pre {
          padding: 1rem 1.4rem;
          max-width: 100%;
          overflow: auto;
          overflow-x: auto;
          color: var(--preformatted);
          background: var(--accent-bg);
          border: 1px solid var(--border);
          border-radius: 5px;
        }
        
        /* Fix embedded code within pre */
        pre code {
          color: var(--preformatted);
          background: none;
          margin: 0;
          padding: 0;
        }

        .has-text-centered {
            text-align: center !important;
        }
        
        p.section-title {
            font-weight: bold;
            padding-top: 0.6rem;
        }
        
</style>