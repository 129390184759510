<template>
  <section class="section">
    <x-survey
      :content="formJson"
      :data="localData"
      :selectedPage="selectedReportPage"
      @completed="onFormSubmitted"
      @activePageChange="setActiveReportPage"
      @update="onFormUpdated"
      :readOnly="false"
    ></x-survey>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import XSurvey from "@/components/XSurvey.vue";

import formJson from "@/models/reportFormV8.json";

export default {
  name: "ComptrollerFormV8",
  components: {
    XSurvey,
  },
  props: {
    // answers
    data: Object,
  },

  data() {
    return {
      formJson: formJson,
      localData: this.data || {},
    };
  },

  watch: {
    data: {
      handler: function (newVal) {
        this.localData = newVal;
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters({
      selectedReportPage: "selection/reportPage",
    }),
  },

  methods: {
    ...mapMutations({
      setActiveReportPage: "selection/setReportPage",
    }),

    onFormSubmitted(result) {
      this.$emit("requestSave", result.data);
    },

    onFormUpdated(result) {
      this.$emit("update", result.data);
    },
  },
};
</script>

<style>
</style>