import axios from "axios"
import firebase from "firebase/compat/app";



async function downloadFromFirebaseStorage(storagePath, mimeType, fileName) {
    var ref = firebase.storage().ref(storagePath);
    const url = await ref.getDownloadURL();
    return downloadFileByUrl(
        url,
        mimeType, fileName
    )
}

function downloadFileByUrl(url, mimeType, fileName) {
    console.log("axios download:", url);
    axios({
        method: "get",
        url: url,
        responseType: "arraybuffer",
    })
        .then((response) => {
            forceFileDownload(response, mimeType, fileName);
        })
        .catch((error) => console.log("error occured:", error.response));
}

function forceFileDownload(response, mimeType, fileName) {
    const url = window.URL.createObjectURL(
        new Blob([response.data], { type: mimeType })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
}

export default { downloadFromFirebaseStorage }