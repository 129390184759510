import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout.vue'
import ClientLayout from '../views/ClientLayout.vue'
import AddClient from '../views/clients/addClient.vue'
import ViewClient from '../views/clients/viewClient.vue'
import EditClient from '../views/clients/editClient.vue'
import ListClients from '../views/clients/listClients.vue'
import ViewReport from '../views/reports/viewReport.vue'
import EditReport from '../views/reports/editReport.vue'
import ViewYearlyReport from '../views/yearlyReports/viewYearlyReport.vue'
import EditYearlyReport from '../views/yearlyReports/editYearlyReport.vue'
import ListReports from '../views/reports/listReports.vue'
import LoginPage from '@/views/auth/Login.vue'
// import LoginPage from '@/views/auth/Login.vue'
import ProfilePage from '@/views/auth/Profile.vue'

import store from '@/store'
Vue.use(VueRouter)

const routes = [{
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      isPublicOnly: true
    }
  },
  // {
  //   path: '/logout',
  //   name: 'Logout',
  //   component: LogoutPage
  // }, 
  {
    path: '/profile',
    name: 'Profile',
    component: ProfilePage,

  },
  {
    path: '/',
    name: 'Home',
    component: Layout,
    children: [{
        path: '/clients/list',
        name: 'ListClients',
        component: ListClients
      }, {
        path: '/clients/add',
        name: 'AddClient',
        component: AddClient
      },
      {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
      }, {
        path: '/client/:clientId',
        component: ClientLayout,

        children: [{
            path: '/',
            name: 'ViewClient',
            component: ViewClient
          },
          {
            path: 'settings',
            name: 'EditClient',
            component: EditClient
          },
          {
            path: 'reports',
            name: 'ListReports',
            component: ListReports
          },

          {
            path: 'report/:reportId',
            name: 'ViewReport',
            component: ViewReport,
          },
          {
            path: 'report/:reportId/edit',
            name: 'EditReport',
            component: EditReport
          },
          {
            path: 'yearly-report/:reportId',
            name: 'ViewYearlyReport',
            component: ViewYearlyReport,
          },
          {
            path: 'yearly-report/:reportId/edit',
            name: 'EditYearlyReport',
            component: EditYearlyReport
          }
        ]
      },
    ]
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// navigation guards
router.beforeEach((to, from, next) => {

  const isAuthed = store.getters["auth/isAuthed"]
  console.log(`from ${from.name} to ${to.name}`)

  console.log(`private page requested: ${to.name}, authed=${isAuthed}`)
  next()

  return;
  // if (to.matched.some(record => record.meta.isPublicOnly)) {
  //   if (isAuthed) {
  //     console.log(`private page requested: ${to.name}, authed=${isAuthed}`)

  //     next({
  //       name: 'Home'
  //     })
  //   } else {
  //     console.log(`private page requested: ${to.name}, authed=${isAuthed}`)

  //     next()
  //   }
  // } else {
  //   if (isAuthed) {
  //     console.log(`private page requested: ${to.name}, authed=${isAuthed}`)

  //     next()
  //   } else {
  //     console.log(`private page requested: ${to.name}, authed=${isAuthed}`)

  //     next({
  //       name: "Login"
  //     })
  //   }

  // }

})

export default router