<template>
  <section class="section" id="firebaseui-auth-container"></section>
</template>

<script>
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import firebase from "firebase/compat/app";

export default {
  name: "LoginPage",
  mounted() {
    let ui = firebaseui.auth.AuthUI.getInstance();
    console.log(ui);

    if (!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    // if (ui.isPendingRedirect()) {
    //   ui.start("#firebaseui-auth-container", uiConfig);
    // }

    var uiConfig = {
      signInSuccessUrl: "/",
      signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID]
    };

    ui.start("#firebaseui-auth-container", uiConfig);
  },
  methods: {}
};
</script>

<style>
</style>