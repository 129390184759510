<template>
  <div class="sidebar-page">
    <section class="sidebar-layout">
      <b-sidebar
        position="static"
        type="is-secondary"
        :reduce="false"
        mobile="reduce"
        open
        :fullheight="true"
        :expand-on-hover="true"
      >
        <div class="p-1 pt-6">
          <b-menu class="is-custom-mobile">
            <b-menu-list>
              <b-menu-item
                icon="home"
                icon-pack="fas"
                label="Cenzor"
                tag="router-link"
                :to="{ name: 'Home' }"
                :active="$route.name == 'Home'"
              ></b-menu-item>
              <b-menu-item
                icon="list"
                icon-pack="fas"
                label="Lista asociatii"
                tag="router-link"
                :to="{ name: 'ListClients' }"
                :active="$route.name == 'ListClients'"
              ></b-menu-item>
              <b-menu-item
                icon="plus"
                icon-pack="fas"
                label="Adauga asociatie"
                tag="router-link"
                :to="{ name: 'AddClient' }"
                :active="$route.name == 'AddClient'"
              ></b-menu-item>
              <b-menu-item v-if="client" expanded :label="client.title">
                <b-menu-item
                  icon="home"
                  icon-pack="fas"
                  label="Vezi"
                  tag="router-link"
                  :to="{ name: 'ViewClient', params: { clientId: client.id } }"
                  :active="$route.name == `ViewClient`"
                  exact
                ></b-menu-item>
                <b-menu-item
                  icon="cog"
                  icon-pack="fa"
                  label="Setari"
                  tag="router-link"
                  :to="{ name: 'EditClient', params: { clientId: client.id } }"
                  :active="$route.name == `EditClient`"
                  exact
                ></b-menu-item>
                <b-menu-item
                  icon="folder"
                  label="Rapoarte"
                  tag="router-link"
                  :to="{ name: 'ListReports' }"
                  :active="$route.name == `ListReports`"
                ></b-menu-item>
              </b-menu-item>

              <b-menu-item
                v-if="report"
                expanded
                :label="`Raport ${report.data.report_year}-${report.data.report_month} (v${report.formVersionString})`"
              >
                <b-menu-item
                  v-for="(page, index) in reportFormPages"
                  v-bind:key="page.name"
                  :label="`${index}. ${page.title}`"
                  :active="page.name == selectedReportPage"
                  @click="setActiveReportPage(page.name)"
                ></b-menu-item>
                <div class="py-2 my-2 px-3">
                  <b-switch v-model="statusPreviewReport">Preview</b-switch>
                </div>
                <div class="py-2 my-2 px-3">
                  <b-switch v-model="statusChecksReport">Verificări</b-switch>
                </div>
              </b-menu-item>
            </b-menu-list>

            <b-menu-list label="Actions">
              <b-menu-item
                icon="user"
                icon-pack="fas"
                :label="user.email"
                tag="router-link"
                :to="{ name: 'Profile' }"
              ></b-menu-item>
              <b-menu-item
                icon="sign-out-alt"
                icon-pack="fas"
                label="Logout"
                @click="signOut"
              ></b-menu-item>
            </b-menu-list>
          </b-menu>
        </div>
        <div class="is-flex-grow-1 p-4">
          <div
            class="
              is-flex
              is-flex-direction-column
              is-align-items-start
              is-align-content-end
              is-justify-content-end
            "
            style="height: 100%"
          >
            <div class="is-flex-grow-1"></div>
            <div>
              <p>Cenzor V{{ appVersion }}</p>
              <p>Database: {{ datastore }}</p>
              <p>Build: {{ buildMode }}</p>
            </div>
          </div>
        </div>
      </b-sidebar>

      <div id="main-page" class="p-1 pt-1" style="flex-grow: 1; overflow: auto">
        <router-view />
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapMutations, mapActions } from "vuex";
import Report from "@/models/report";

export default {
  name: "Layout",
  components: {},
  data() {
    return {
      // reportFormPages: Report.currentForm.pages,
    };
  },
  computed: {
    ...mapGetters({
      getClientById: "clients/getById",
      clientsList: "clients/allAsArray",
      getReportById: "reports/getById",
      getYearlyReportById: "yearlyReports/getById",
      selectedReportPage: "selection/reportPage",
      boolPreviewReport: "selection/boolPreviewReport",
      boolChecksReport: "selection/boolChecksReport",
      user: "auth/user",
      appVersion: "selection/appVersion",
    }),
    datastore() {
      return process.env.VUE_APP_DATASTORE;
    },
    buildMode() {
      return process.env.NODE_ENV;
    },
    statusPreviewReport: {
      get() {
        return this.boolPreviewReport;
      },
      set(value) {
        return this.setPreviewReport(value);
      },
    },
    statusChecksReport: {
      get() {
        return this.boolChecksReport;
      },
      set(value) {
        return this.setChecksReport(value);
      },
    },
    clientId() {
      return this.$route.params.clientId || null;
    },
    client() {
      if (!this.clientId) return null;
      return this.getClientById(this.clientId);
    },
    reportId() {
      if (!this.clientId) return null;


      if (this.$route.name.includes("YarlyReport")) {
        return this.getYearlyReportById(this.reportId);
      }
      

      return this.$route.params.reportId || null;
    },
    report() {
      console.log ("route is ", this.$route.name);
      if (!this.reportId) return null;

      if (this.$route.name.includes("YearlyReport")) {
        return this.getYearlyReportById(this.reportId);
      }
      
      return this.getReportById(this.reportId);
    },

    reportFormPages(){
      if (this.$route.name.includes("YearlyReport")) {
        return [];
      }
      return Report.currentForm.pages;
    }
  },
  methods: {
    ...mapMutations({
      setActiveReportPage: "selection/setReportPage",
      setPreviewReport: "selection/setPreviewReport",
      setChecksReport: "selection/setChecksReport",
    }),
    ...mapActions({
      signOut: "auth/signOut",
    }),
  },
};
</script>


<style lang="scss" >
.p-1 {
  padding: 1em;
}
.sidebar-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  .sidebar-layout {
    display: flex;
    flex-direction: row;
    height: 100vh;
    // min-width: 100%;
    // min-height: 100vh;
  }
}
@media screen and (max-width: 1023px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini-mobile {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>