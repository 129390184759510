<template>
  <section>
    ProfilePage
    <div>{{user}}</div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProfilePage",
  computed: {
    ...mapGetters({ user: "auth/user" })
  }
};
</script>

<style>
</style>