<template>
  <div
    id="main-page-content-view"
    class="is-flex is-flex-direction-column"
    style="width: 100%; max-height: 100vh"
  >
    <div
      class="
        is-flex
        is-flex-direction-row
        is-justify-content-space-around
        is-align-content-center
        mb-2
        p-2
      "
      style="background: #ebfffc"
    >
      <b-button
        tag="router-link"
        :to="{ name: 'EditReport', params: { reportId: reportId } }"
        >Editează</b-button
      >
      <b-button v-if="report && report.waitingForPrint" disabled
        >generating PDF...</b-button
      >
      <b-button
        v-if="report && report.storagePath && !report.waitingForPrint"
        @click="downloadReport(report)"
        >Descarcă</b-button
      >

      <b-button
        v-if="!report || !report.storagePath"
        @click="regeneratePdf(report)"
        >Regenerează PDF</b-button
      >
    </div>

    <div class="columns is-centered m-0" style="max-height: 90vh">
      <div
        v-if="report && boolPreviewReport"
        class="column is-three-fifths mr-2"
        style="overflow-y: auto"
      >
        <div class="box has-background-light">
          <div v-html="report.html"></div>
        </div>
      </div>
      <div class="column ml-2" style="overflow-y: auto">
        <div class="" v-if="report" style="position: relative">
          <x-survey
            :content="formContent"
            :data="report.data"
            :selectedPage="selectedReportPage"
            @activePageChange="setActiveReportPage"
            :readOnly="true"
          ></x-survey>
        </div>
      </div>
    </div>

    <!-- show while trying to submit answer to server -->
    <b-loading
      :is-full-page="isFullPage"
      :active.sync="savingResult"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import XSurvey from "@/components/XSurvey.vue";
import { ToastProgrammatic as Toast } from "buefy";

import Report from "@/models/report";
import ForceDownload from "@/utils/forceDownload";

export default {
  name: "ViewReportPage",

  components: {
    XSurvey,
    // ReportSummary
  },

  computed: {
    ...mapGetters({
      getReportById: "reports/getById",
      selectedReportPage: "selection/reportPage",
      boolPreviewReport: "selection/boolPreviewReport",
      getClientById: "clients/getById",
    }),
    reportId() {
      return this.$route.params.reportId;
    },
    report() {
      if (!this.reportId) return null;
      return this.getReportById(this.reportId);
    },
    formContent() {
      return Report.getFormByVersionString(
        this.report.formVersionString || "4"
      );
    },
  },

  data: function () {
    return {
      isFullPage: false,
      savingResult: false,
    };
  },

  methods: {
    ...mapMutations({
      setActiveReportPage: "selection/setReportPage",
    }),

    async regeneratePdf(report) {
      const patchedReport = JSON.parse(JSON.stringify(report, null, 2));

      patchedReport.waitingForPrint = true;
      delete patchedReport.storagePath;

      try {
        const newId = await this.$store.dispatch("reports/set", patchedReport);
        console.log("updated report with id ", newId, patchedReport);
      } catch (error) {
        console.error("saving report error: ", error);
        this.isErrorPosting = true;
        Toast.open({
          message: "Ooops! Could not regenerate report...",
          type: "is=danger",
          position: "is-bottom",
          duration: 5000,
        });
      }

      this.savingResult = false;
    },

    async downloadReport(report) {
      return ForceDownload.downloadFromFirebaseStorage(
        report.storagePath,
        "application/pdf",
        Report.reportFilename(report)
      );
    },
  },
};
</script>

<style>
</style>
