const clientModule = {
  firestorePath: `clients`,
  firestoreRefType: 'collection', // or 'doc'
  // name for vuex module
  moduleName: 'clients',
  statePropName: 'data',
  namespaced: true, // automatically added
  sync: {
    preventInitialDocInsertion: true,
  },

  // this object is your store module (will be added as '/projectModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    allAsArray: state => Object.values(state.data || {}),
    allAsObject: state => state.data || {},
    getById: state => (id) => {
      const res = state.data[id]
      if (res) return res
      console.log("WARNING: could not find client with id:", id)
      return null
    }
  },
  mutations: {},
  actions: {},
}

export default clientModule