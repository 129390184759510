import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Buefy from 'buefy'

import firebase from 'firebase/compat/app'
import VueFormulate from "@braid/vue-formulate"

import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import '@braid/vue-formulate/themes/snow/snow.scss';

import ComptrollerReportMissing from "@/components/reports/ComptrollerReportMissing.vue";
import ComptrollerReportV4 from "@/components/reports/ComptrollerReportV4.vue";
import ComptrollerReportV5 from "@/components/reports/ComptrollerReportV5.vue";
import ComptrollerReportV6 from "@/components/reports/ComptrollerReportV6.vue";
import ComptrollerReportV7 from "@/components/reports/ComptrollerReportV7.vue";
import ComptrollerReportV8 from "@/components/reports/ComptrollerReportV8.vue";

import ComptrollerYearlyReportV8 from "@/components/reports/ComptrollerYearlyReportV8.vue";
import ComptrollerYearlyReportV8a from "@/components/reports/ComptrollerYearlyReportV8a.vue";

import ComptrollerFormMissing from "@/components/forms/ComptrollerFormMissing.vue"
import ComptrollerFormV4 from "@/components/forms/ComptrollerFormV4"
import ComptrollerFormV5 from "@/components/forms/ComptrollerFormV5"
import ComptrollerFormV6 from "@/components/forms/ComptrollerFormV6"
import ComptrollerFormV7 from "@/components/forms/ComptrollerFormV7"
import ComptrollerFormV8 from "@/components/forms/ComptrollerFormV8"

import ComptrollerYearlyFormV8 from "@/components/forms/ComptrollerYearlyFormV8"
import ComptrollerYearlyFormV8a from "@/components/forms/ComptrollerYearlyFormV8a"

Vue.use(VueSidebarMenu)

Vue.config.productionTip = false

Vue.use(VueFormulate)
Vue.use(Buefy, {
  defaultIconPack: 'fas',
})

Vue.component('ComptrollerReportV4', ComptrollerReportV4)
Vue.component('ComptrollerReportV5', ComptrollerReportV5)
Vue.component('ComptrollerReportV6', ComptrollerReportV6)
Vue.component('ComptrollerReportV7', ComptrollerReportV7)
Vue.component('ComptrollerReportV8', ComptrollerReportV8)
Vue.component('ComptrollerYearlyReportV8', ComptrollerYearlyReportV8)
Vue.component('ComptrollerYearlyReportV8a', ComptrollerYearlyReportV8a)
Vue.component('ComptrollerReportMissing', ComptrollerReportMissing)

Vue.component('ComptrollerFormV4', ComptrollerFormV4)
Vue.component('ComptrollerFormV5', ComptrollerFormV5)
Vue.component('ComptrollerFormV6', ComptrollerFormV6)
Vue.component('ComptrollerFormV7', ComptrollerFormV7)
Vue.component('ComptrollerFormV8', ComptrollerFormV8)

Vue.component('ComptrollerYearlyFormV8', ComptrollerYearlyFormV8)
Vue.component('ComptrollerYearlyFormV8a', ComptrollerYearlyFormV8a)

Vue.component('ComptrollerFormMissing', ComptrollerFormMissing)


// create the Vue app but do not mount it before we receive onAuthStateChanged
const app = new Vue({
  router,
  store,
  render: h => h(App)
})


firebase.auth().onAuthStateChanged(async firebaseUser => {


  const newUser = firebaseUser

  console.log(`firebase auth state changed to ${JSON.stringify(newUser ? newUser.uid : null)}`)

  store.commit("auth/SET_USER", newUser)
  const isAuthed = Boolean(newUser)
  const {
    meta
  } = router.history.current;

  if (!meta.isPublicOnly && !isAuthed) {
    router.push({
      name: "Login"
    })
  }
  store.dispatch("clients/openDBChannel")
  app.$mount("#app")
});