var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', [_vm._v("Total " + _vm._s(_vm.countAllCustomers) + " asociatii, din care " + _vm._s(_vm.countActiveCustomers) + " active.")]), _c('b-table', {
    attrs: {
      "data": _vm.clientsList,
      "debounce-search": 100,
      "default-sort": "title",
      "sticky-header": "",
      "height": "100%"
    }
  }, [_c('b-table-column', {
    attrs: {
      "field": "title",
      "label": "nume",
      "searchable": true,
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "searchable",
      fn: function (props) {
        return [_c('b-input', {
          attrs: {
            "placeholder": "Search...",
            "icon": "search",
            "size": "is-small",
            "icon-right": "close-circle",
            "icon-right-clickable": ""
          },
          on: {
            "icon-right-click": function ($event) {
              props.filters.title = null;
            }
          },
          model: {
            value: props.filters.title,
            callback: function ($$v) {
              _vm.$set(props.filters, "title", $$v);
            },
            expression: "props.filters.title"
          }
        })];
      }
    }, {
      key: "default",
      fn: function (props) {
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'ViewClient',
              params: {
                clientId: props.row.id
              }
            }
          }
        }, [_vm._v(_vm._s(props.row.title || "fara nume"))])];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "field": "name_official",
      "label": "denumirea oficiala",
      "searchable": true,
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "searchable",
      fn: function (props) {
        return [_c('b-input', {
          attrs: {
            "placeholder": "Search...",
            "icon": "search",
            "size": "is-small",
            "icon-right": "close-circle",
            "icon-right-clickable": ""
          },
          on: {
            "icon-right-click": function ($event) {
              props.filters.name_official = null;
            }
          },
          model: {
            value: props.filters.name_official,
            callback: function ($$v) {
              _vm.$set(props.filters, "name_official", $$v);
            },
            expression: "props.filters.name_official"
          }
        })];
      }
    }, {
      key: "default",
      fn: function (props) {
        return [_vm._v(_vm._s(props.row.name_official))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "field": "address",
      "label": "adresa",
      "searchable": true,
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "searchable",
      fn: function (props) {
        return [_c('b-input', {
          attrs: {
            "placeholder": "Search...",
            "icon": "search",
            "size": "is-small",
            "icon-right": "close-circle",
            "icon-right-clickable": ""
          },
          on: {
            "icon-right-click": function ($event) {
              props.filters.address = null;
            }
          },
          model: {
            value: props.filters.address,
            callback: function ($$v) {
              _vm.$set(props.filters, "address", $$v);
            },
            expression: "props.filters.address"
          }
        })];
      }
    }, {
      key: "default",
      fn: function (props) {
        return [_vm._v(_vm._s(props.row.address))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "field": "status",
      "label": "status",
      "searchable": true,
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "searchable",
      fn: function (props) {
        return [_c('b-input', {
          attrs: {
            "placeholder": "Search...",
            "icon": "search",
            "size": "is-small",
            "icon-right": "close-circle",
            "icon-right-clickable": ""
          },
          on: {
            "icon-right-click": function ($event) {
              props.filters.status = null;
            }
          },
          model: {
            value: props.filters.contract_stop_date,
            callback: function ($$v) {
              _vm.$set(props.filters, "contract_stop_date", $$v);
            },
            expression: "props.filters.contract_stop_date"
          }
        })];
      }
    }, {
      key: "default",
      fn: function (props) {
        return [!props.row.contract_stop_date ? [_vm._v("active")] : [_vm._v("stop " + _vm._s(props.row.contract_stop_date))]];
      }
    }])
  }), _c('template', {
    slot: "empty"
  }, [_c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "content has-text-grey has-text-centered"
  }, [_c('p', [_c('b-icon', {
    attrs: {
      "icon": "emoticon-sad",
      "size": "is-large"
    }
  })], 1), _c('p', [_vm._v("Nothing here.")])])])])], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }