var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "is-flex is-flex-direction-column",
    staticStyle: {
      "width": "100%",
      "max-height": "100vh"
    },
    attrs: {
      "id": "main-page-content-view"
    }
  }, [_c('div', {
    staticClass: "is-flex is-flex-direction-row is-justify-content-space-around is-align-content-center mb-2 p-2",
    staticStyle: {
      "background": "#ebfffc"
    }
  }, [_c('b-button', {
    attrs: {
      "tag": "router-link",
      "to": {
        name: 'EditReport',
        params: {
          reportId: _vm.reportId
        }
      }
    }
  }, [_vm._v("Editează")]), _vm.report && _vm.report.waitingForPrint ? _c('b-button', {
    attrs: {
      "disabled": ""
    }
  }, [_vm._v("generating PDF...")]) : _vm._e(), _vm.report && _vm.report.storagePath && !_vm.report.waitingForPrint ? _c('b-button', {
    on: {
      "click": function ($event) {
        return _vm.downloadReport(_vm.report);
      }
    }
  }, [_vm._v("Descarcă")]) : _vm._e(), !_vm.report || !_vm.report.storagePath ? _c('b-button', {
    on: {
      "click": function ($event) {
        return _vm.regeneratePdf(_vm.report);
      }
    }
  }, [_vm._v("Regenerează PDF")]) : _vm._e()], 1), _c('div', {
    staticClass: "columns is-centered m-0",
    staticStyle: {
      "max-height": "90vh"
    }
  }, [_vm.report && _vm.boolPreviewReport ? _c('div', {
    staticClass: "column is-three-fifths mr-2",
    staticStyle: {
      "overflow-y": "auto"
    }
  }, [_c('div', {
    staticClass: "box has-background-light"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.report.html)
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "column ml-2",
    staticStyle: {
      "overflow-y": "auto"
    }
  }, [_vm.report ? _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('x-survey', {
    attrs: {
      "content": _vm.formContent,
      "data": _vm.report.data,
      "selectedPage": _vm.selectedReportPage,
      "readOnly": true
    },
    on: {
      "activePageChange": _vm.setActiveReportPage
    }
  })], 1) : _vm._e()])]), _c('b-loading', {
    attrs: {
      "is-full-page": _vm.isFullPage,
      "active": _vm.savingResult,
      "can-cancel": true
    },
    on: {
      "update:active": function ($event) {
        _vm.savingResult = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }