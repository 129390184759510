var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "is-flex is-flex-direction-column",
    staticStyle: {
      "width": "100%",
      "max-height": "100vh"
    },
    attrs: {
      "id": "main-page-content"
    }
  }, [_c('div', {
    staticClass: "is-flex is-flex-direction-row is-justify-content-space-around is-align-content-center mb-2",
    staticStyle: {
      "background": "#ebfffc"
    }
  }, [_c('b-field', {
    attrs: {
      "horizontal": "",
      "label": "Versiunea:"
    }
  }, [_c('b-select', {
    model: {
      value: _vm.localFormVersion,
      callback: function ($$v) {
        _vm.localFormVersion = $$v;
      },
      expression: "localFormVersion"
    }
  }, [_c('option', {
    attrs: {
      "value": "4"
    }
  }, [_vm._v("v4")]), _c('option', {
    attrs: {
      "value": "5"
    }
  }, [_vm._v("v5")]), _c('option', {
    attrs: {
      "value": "6"
    }
  }, [_vm._v("v6")]), _c('option', {
    attrs: {
      "value": "7"
    }
  }, [_vm._v("v7")]), _c('option', {
    attrs: {
      "value": "8"
    }
  }, [_vm._v("v8")])])], 1), _vm.report ? _c('div', {
    staticClass: "buttons"
  }, [_c('b-button', {
    on: {
      "click": _vm.saveChanges
    }
  }, [_vm._v("Salvează")]), _c('b-button', {
    on: {
      "click": _vm.cancelChanges
    }
  }, [_vm._v("Anulează")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "columns is-centered m-0",
    staticStyle: {
      "max-height": "90vh"
    }
  }, [_c('div', {
    staticClass: "column mr-2",
    staticStyle: {
      "overflow-y": "auto",
      "border": "1px solid"
    }
  }, [_vm.report ? _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_vm.formComponent ? _c(_vm.formComponent, {
    tag: "component",
    attrs: {
      "data": _vm.report.data,
      "content": _vm.formContent
    },
    on: {
      "update": _vm.updateReportData,
      "requestSave": _vm.saveReportData
    }
  }) : _c('ComptrollerFormMissing', {
    attrs: {
      "version": _vm.localFormVersion
    }
  })], 1) : _vm._e()]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.boolPreviewReport,
      expression: "boolPreviewReport"
    }],
    staticClass: "column p-0",
    staticStyle: {
      "overflow-y": "auto",
      "border": "1px solid"
    }
  }, [_c('div', {
    ref: "contentEl",
    staticClass: "box has-background-light"
  }, [_c(_vm.reportComponent, {
    tag: "component",
    attrs: {
      "data": _vm.localReportData,
      "formVersion": _vm.localFormVersion,
      "client": _vm.client,
      "previousMonthData": _vm.previousMonthReportData,
      "show-checks": _vm.boolChecksReport
    }
  })], 1)])]), _c('b-loading', {
    attrs: {
      "is-full-page": _vm.isFullPage,
      "active": _vm.savingResult,
      "can-cancel": true
    },
    on: {
      "update:active": function ($event) {
        _vm.savingResult = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }