var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "section"
  }, [_c('p', [_vm._v("Formular lipsa: V" + _vm._s(_vm.version))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }