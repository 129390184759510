<template>
  <section class="section">
    <list-of-reports :reports="reportsList"></list-of-reports>
    <div>
      <b-button @click="createNewReport">Raport nou</b-button>
    </div>

    <list-of-yearly-reports :reports="yearlyReportsList"></list-of-yearly-reports>
    <div>
      <b-button @click="createNewYearlyReport">Raport anual</b-button>
    </div>
  </section>
</template>

<script >
import { mapGetters, mapActions } from "vuex";
import ListOfReports from "@/components/ListOfReports";
import ListOfYearlyReports from "@/components/ListOfYearlyReports";
import Report from "@/models/report";
import YearlyReport from "@/models/yearlyReport";

export default {
  name: "ListReports",
  data() {
    return {};
  },
  components: { ListOfReports, ListOfYearlyReports },
  computed: {
    ...mapGetters({
      // user: "auth/user",
      reportsList: "reports/allAsArray" || [],
      yearlyReportsList: "yearlyReports/allAsArray" || [],
      getClientById: "clients/getById",
    }),
    clientId() {
      return this.$route.params.clientId;
    },
    client() {
      if (!this.clientId) return null;
      return this.getClientById(this.clientId);
    },
  },
  methods: {
    ...mapActions({
      saveReport: "reports/set",
      saveYearlyReport: "yearlyReports/set",
    }),

    createNewReport() {
      const newReport = Report.newReport(null, this.client);
      this.saveReport(newReport);
    },

    createNewYearlyReport() {
      const newYearlyReport = YearlyReport.newYearlyReport(null, this.client);
      console.log('new yearly report', newYearlyReport);
      this.saveYearlyReport(newYearlyReport);
    },
  },
};
</script>

<style>
.container {
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.title {
  font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  display: block;
  font-weight: 300;
  font-size: 100px;
  color: #35495e;
  letter-spacing: 1px;
}

.subtitle {
  font-weight: 300;
  font-size: 42px;
  color: #526488;
  word-spacing: 5px;
  padding-bottom: 15px;
}

.links {
  padding-top: 15px;
}
</style>
