import { render, staticRenderFns } from "./ListOfReports.vue?vue&type=template&id=9bae4a86"
import script from "./ListOfReports.vue?vue&type=script&lang=js"
export * from "./ListOfReports.vue?vue&type=script&lang=js"
import style0 from "./ListOfReports.vue?vue&type=style&index=0&id=9bae4a86&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports