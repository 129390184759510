var render = function render(){
  var _vm$previousMonthData, _vm$reportData;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showChecks ? _c('div', [_c('pre', [_vm._v("      " + _vm._s(_vm.clientData) + "\n    ")]), _c('pre', [_vm._v("      " + _vm._s(_vm.reportData) + "\n    ")])]) : _vm._e(), _vm.showChecks ? _c('div', {
    attrs: {
      "id": "report-checks"
    }
  }, [_c('p', [_vm._v("Verificări:")]), _c('p', {
    staticClass: "has-text-grey"
  }, [_vm._v("versiune formular: " + _vm._s(_vm.formVersion))]), _c('p', {
    staticClass: "has-text-grey"
  }, [_vm._v("versiune raport: " + _vm._s(_vm.reportVersion))]), _vm._l(_vm.missingFormDataErrors, function (err) {
    return _c('p', {
      key: err,
      staticClass: "has-text-danger"
    }, [_vm._v(" " + _vm._s(err) + " ")]);
  }), _vm._l(_vm.missingPreviousMonthDataErrors, function (err) {
    return _c('p', {
      key: err,
      staticClass: "has-text-danger"
    }, [_vm._v(" " + _vm._s(err) + " ")]);
  }), !Boolean(_vm.previousMonthData) ? _c('p', {
    staticClass: "has-text-danger"
  }) : _vm._e()], 2) : _vm._e(), _c('div', {
    staticClass: "content has-text-left",
    attrs: {
      "id": "report-content"
    }
  }, [_c('img', {
    staticStyle: {
      "max-width": "200px"
    },
    attrs: {
      "src": _vm.logoEntity,
      "alt": "logo"
    }
  }), _c('h1', {
    staticClass: "title has-text-centered"
  }, [_vm._v(_vm._s(_vm.reportTitle))]), _c('h2', {
    staticClass: "subtitle has-text-centered"
  }, [_vm._v(_vm._s(_vm.reportSubtitle))]), _c('h4', {
    staticClass: "subtitle has-text-centered"
  }, [_vm._v(" " + _vm._s(_vm.reportPeriod) + " ")]), _c('p', [_c('strong', [_vm._v(_vm._s(_vm.entityOfficialName))]), _vm._v(", reprezentată prin Mareş Mihaela, în calitate de cenzor pentru "), _c('strong', [_vm._v(_vm._s(_vm.clientData.name_official || "________________"))]), _vm._v(", cu sediul în "), _c('strong', [_vm._v(_vm._s(_vm.clientData.address || "________________"))]), _vm._v(", am analizat și verificat documentele care stau la baza întocmirii rapoartelor și listelor de plată pentru luna "), _c('strong', [_vm._v(_vm._s(_vm.reportPeriod || "________________"))]), _vm._v(", conform cu Legea nr. 196/2018 privind înfiinţarea, organizarea şi funcţionarea Asociaţiilor de Proprietari. ")]), _c('p', {
    staticClass: "section-title"
  }, [_vm._v("Repartizarea facturilor")]), _c('p', [_vm._v(" Suma totală a facturilor repartizate în listă este în valoare de " + _vm._s(typeof _vm.reportData.repartizarea_facturilor_suma_facturilor_repartizate == "number" ? _vm.reportData.repartizarea_facturilor_suma_facturilor_repartizate : "___") + " RON, iar totalul lunar din lista de plată este în valoare de " + _vm._s(typeof _vm.reportData.repartizarea_facturilor_total_lunar_lista_de_plata == "number" ? _vm.reportData.repartizarea_facturilor_total_lunar_lista_de_plata : "___") + " RON. ")]), _vm.sumaCotelorVsSumaFacturilorRepartizateEsteOk ? [_c('p', [_vm._v(" Suma totală a facturilor repartizate în listă este egală/aproximativ egală cu totalul lunar din lista de intreținere. ")])] : [_c('p', [_vm._v(" Diferența între suma cotelor apartamentelor și suma facturilor repartizate în listă este " + _vm._s(this.reportData.repartizarea_facturilor_suma_facturilor_repartizate - this.reportData.repartizarea_facturilor_total_lunar_lista_de_plata) + " RON. ")]), _c('ul', [_c('strong', [_vm._v("Observații:")]), _vm._l(_vm.formatListItems(_vm.reportData.repartizarea_facturilor_neconcordante_sume), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  })], 2)], _vm.reportData.repartizarea_facturilor_facturile_din_liste_sunt_corecte == 'true' ? [_c('p', [_vm._v(" Toate facturile repartizate în liste există fizic și sunt trecute cu sumele și datele corecte. ")])] : [_vm._m(0), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.repartizarea_facturilor_neconcordante_facturi_din_liste), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)], _vm.reportData.facturi_achitate_fonduri == 'true' ? [_vm.reportData.repartizarea_facturilor_facturile_din_fonduri_sunt_corecte == 'true' ? [_c('p', [_vm._v(" Toate facturile achitate din fonduri există fizic și sunt trecute cu sumele și datele corecte. ")])] : [_vm._m(1), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.repartizarea_facturilor_neconcordante_facturi_din_fonduri), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)]] : _vm._e(), _c('p', {
    staticClass: "section-title"
  }, [_vm._v("Registrul de casă:")]), _vm.reportData.asociatia_exclusiv_banca == 'true' ? [_c('p', [_vm._v("Asociația funcționează exclusiv prin bancă.")])] : [_vm.reportData.primit_registrul_de_casa !== 'true' ? [_vm._m(2)] : [((_vm$previousMonthData = _vm.previousMonthData) === null || _vm$previousMonthData === void 0 ? void 0 : _vm$previousMonthData.asociatia_operatiuni_cash_luna_raportului) === 'false' ? [_c('p', [_vm._v("În luna precedentă nu au existat operațiuni în numerar.")])] : _vm._e(), ((_vm$reportData = _vm.reportData) === null || _vm$reportData === void 0 ? void 0 : _vm$reportData.asociatia_operatiuni_cash_luna_raportului) === 'false' ? [_c('p', [_vm._v("Nu au existat operațiuni în numerar în lună.")])] : [_vm.neconcordantaLunaTrecutaPrimaChitanta ? [_vm._m(3)] : _vm._e(), _vm.trebuieVerificateChitantele ? [_c('p', [_vm._v(" Numărul primei chitanțe înregistrate în lună este " + _vm._s(typeof _vm.reportData.registrul_de_casa_nr_prima_chitanta == "number" ? _vm.reportData.registrul_de_casa_nr_prima_chitanta : "___") + ". ")]), _c('p', [_vm._v(" Numărul ultimei chitanțe înregistrate în lună este " + _vm._s(typeof _vm.reportData.registrul_de_casa_nr_ultima_chitanta == "number" ? _vm.reportData.registrul_de_casa_nr_ultima_chitanta : "___") + ". ")])] : _vm._e(), _vm.neconcordantaLunaTrecutaRegistrulDeCasa ? [_vm._m(4)] : _vm._e(), _c('p', [_vm._v(" Soldul de la casă este în valoare de " + _vm._s(typeof _vm.reportData.registrul_de_casa_sold_casa == "number" ? _vm.reportData.registrul_de_casa_sold_casa : "___") + " RON. Nu s-a efectuat numărarea lichidităților. ")]), _vm.reportData.registrul_de_casa_incasarile_sunt_corecte === 'true' ? [_c('p', [_vm._v(" Toate încasările în numerar sunt trecute cu sumele și datele corecte în Registrul de casă. ")])] : [_vm._m(5), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.registrul_de_casa_neconcordante_incasari), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)], _vm.reportData.registrul_de_casa_platile_sunt_corecte === 'true' ? [_c('p', [_vm._v(" Toate plățile în numerar sunt trecute cu sumele și datele corecte în Registrul de casă. ")])] : [_vm._m(6), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.registrul_de_casa_neconcordante_plati), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)], _vm.reportData.registrul_de_casa_este_corect_pe_zile === 'true' ? [_c('p', [_vm._v(" Fiecare zi trecută în Registrul de casă are totalul de plăți corect, totalul de încasări corect și totalul zilnic corect. ")])] : [_vm._m(7), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.registrul_de_casa_neconcordante_pe_zile), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)], parseInt(_vm.reportData.registrul_de_casa_sold_casa) > 1000 && _vm.reportData.popriri_conturi == 'true' ? [_c('p', [_vm._v(" Soldul de la casă depășește plafonul stabilit de lege în valoare de 1000 de RON, dar valoarea depășită nu poate fi depusă în contul de la bancă deoarece pe contul Asociației există popriri. ")])] : _vm._e(), parseInt(_vm.reportData.registrul_de_casa_sold_casa) > 1000 && _vm.reportData.popriri_conturi == 'false' ? [_vm._m(8)] : _vm._e(), _vm.diferentaSoldRegCasaVsActivPasiv ? [_c('p', [_c('strong', [_vm._v("Soldul de casă conform Registrului de casă nu este egal cu soldul de la casă conform Situației de Activ/Pasiv. Diferența: " + _vm._s(_vm.diferentaSoldRegCasaVsActivPasiv) + " RON. ")])])] : _vm._e()]]], _c('p', {
    staticClass: "section-title"
  }, [_vm._v("Registrul de bancă:")]), _vm.neconcordantaLunaTrecutaRegistrulDeBanca ? [_vm._m(9)] : _vm._e(), _vm.diferentaSoldRegBancaVsActivPasiv ? [_c('p', [_c('strong', [_vm._v(" Soldul de la bancă conform Registrului de bancă nu este egal cu soldul de la bancă conform Situației de Activ/Pasiv. Diferența: " + _vm._s(_vm.diferentaSoldRegBancaVsActivPasiv) + "RON. ")])])] : _vm._e(), _vm.reportData.registrul_de_banca_vs_extras_cont_este_ok == 'true' ? [_c('p', [_vm._v(" Extrasul de cont de la bancă este înregistrat corect cu datele și sumele corecte în Registrul de bancă/Registrul jurnal. ")])] : [_vm._m(10), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.registrul_de_banca_neconcordante), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)], typeof _vm.soldBanca == 'number' ? [_c('p', [_vm._v(" Soldul de la bancă este în valoare de " + _vm._s(_vm.soldBanca) + " RON"), _vm.soldBancaDetaliat ? _c('span', [_vm._v(", " + _vm._s(_vm.soldBancaDetaliat))]) : _vm._e(), _vm._v(". ")])] : [_c('p', [_vm._v("LIPSEȘTE soldul de la bancă !!!")])], _c('p', {
    staticClass: "section-title"
  }, [_vm._v("Situația de Activ/Pasiv:")]), _vm.reportData.primit_activ_pasiv == 'false' ? [_vm._m(11)] : [_c('p', [_vm._v(" Totalul elementelor de Activ este în valoare de: " + _vm._s(typeof _vm.reportData.activ_pasiv_total_activ == "number" ? _vm.reportData.activ_pasiv_total_activ : "___") + " RON. ")]), _c('p', [_vm._v(" Totalul elementelor de Pasiv este în valoare de: " + _vm._s(typeof _vm.reportData.activ_pasiv_total_pasiv == "number" ? _vm.reportData.activ_pasiv_total_pasiv : "___") + " ")]), _c('p', [_vm._v(" Soldul fondului de rulment este în valoare de: " + _vm._s(typeof _vm.reportData.activ_pasiv_sold_fond_rulment == "number" ? _vm.reportData.activ_pasiv_sold_fond_rulment : "___") + " RON. ")]), _c('p', [_vm._v(" Soldul fondului de reparații este în valoare de: " + _vm._s(typeof _vm.reportData.activ_pasiv_sold_fond_reparatii == "number" ? _vm.reportData.activ_pasiv_sold_fond_reparatii : "___") + " RON. ")]), _c('p', [_vm._v(" Soldul din Registrul de bancă la data emiterii Situației de Activ/Pasiv este în valoare de: " + _vm._s(typeof _vm.reportData.activ_pasiv_sold_banca == "number" ? _vm.reportData.activ_pasiv_sold_banca : "___") + " RON. ")]), _c('p', [_vm._v(" Soldul din Registrul de casă la data emiterii Situației de Activ/Pasiv este în valoare de: " + _vm._s(typeof _vm.reportData.activ_pasiv_sold_casa == "number" ? _vm.reportData.activ_pasiv_sold_casa : "___") + " RON. ")]), _vm.situatiaActivePasiveEsteEchilibrata ? [_c('p', [_vm._v("Situația de Activ/Pasiv este echilibrată.")])] : [_vm._m(12), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.activ_pasiv_neconcordante), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)]], _c('p', {
    staticClass: "section-title"
  }, [_vm._v("Ștatul de salarii:")]), _vm.reportData.asociatia_are_angajati == 'false' ? [_c('p', [_vm._v("Asociația nu are angajați.")])] : [_vm.reportData.primit_statul_de_plata !== 'true' ? _c('p', [_c('strong', [_vm._v("Nu am primit ștatul de plată!")])]) : _vm._e(), _vm.reportData.statul_de_salarii_contributii_corecte == 'true' ? [_c('p', [_vm._v("Contribuțiile calculate pe ștatul de plată sunt corecte.")])] : [_vm._m(13), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.statul_de_salarii_neconcordante_contributii), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)], _vm.este_luna_de_depunere_d112 ? [_vm.reportData.statul_de_salarii_d112_este_corecta == 'true' ? [_c('p', [_vm._v("Declarația 112 este întocmită corespunzător.")])] : [_vm._m(14), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.statul_de_salarii_neconcordante_d112), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)], _vm.reportData.statul_de_salarii_recipisa_d112_este_ok == 'true' ? [_c('p', [_vm._v(" Există recipisa de depunere fără erori de validare pentru Declarația 112. ")])] : [_vm._m(15)]] : [_c('p', [_vm._v("Asociația depune Declarația 112 trimestrial.")])]], _vm.reportData.observatii_generale && _vm.reportData.observatii_generale.length > 0 ? [_vm._m(16), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.observatii_generale), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)] : _vm._e(), _c('p', {
    staticStyle: {
      "margin-top": "18pt"
    }
  }, [_vm._v(" Contabilitatea Asociației este organizată în partidă simplă în conformitate cu prevederile Legii Contabilității nr. 82/1999 republicată cu modificările și completările ulterioare. ")]), _c('div', {
    staticClass: "columns is-centered is-vcentered"
  }, [_c('div', {
    staticClass: "column"
  }, [_c('img', {
    staticStyle: {
      "margin": "30pt",
      "display": "inline"
    },
    attrs: {
      "src": _vm.entityStampFile,
      "alt": "stamp",
      "width": "84pt"
    }
  })]), _c('div', {
    staticClass: "column"
  }, [_c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_c('strong', [_vm._v("Cenzor, " + _vm._s(_vm.reportDate))])])])])], 2)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la repartizarea facturilor: ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la facturile achitate din fonduri: ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("NU am primit registrul de casă!!!")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v(" Numărul primei chitanțe de încasare din lună nu corespunde cu numărul ultimei chitanțe de încasare din luna precedentă. ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v(" Soldul inițial din Registrul de casă din lună diferă față de soldul final al Registrului de casă de luna trecută; lipsesc documente justificative. ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la încasările din Registrul de casă: ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la plățile din Registrul de casă: ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la Registrul de casă:")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" Soldul de la casă este mai mare decât plafonul prevăzut de lege, în valoare de 1000 de RON; "), _c('strong', [_vm._v("recomandăm")]), _vm._v(" depunerea diferenței în contul de la bancă. ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v(" Soldul inițial din Registrul de bancă din lună diferă față de soldul final al Registrului de bancă de luna trecută; lipsesc documente justificative. ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la Registrul de bancă:")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Nu am primit Situația Activ/Pasiv!!!")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la situația de Activ/Pasiv: ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la calculul contribuțiilor pe ștatul de plată: ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la Declarația 112:")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Nu am primit")]), _vm._v(" recipisa de depunere fără erori de validare pentru Declarația 112. ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații:")])]);

}]

export { render, staticRenderFns }