<template>
  <section class="section">
    <p>Formular lipsa: V{{ version }}</p>
  </section>
</template>

<script>
export default {
  name: "ComptrollerFormMissing",

  props: {
    version: String,
  },
};
</script>

<style>
</style>