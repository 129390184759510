export default {
  newClient({
    title = "",
    name_official = "",
    address = "",
    d112_frequency = "monthly",
    first_month = "",
    contract_start_date = "",
    contract_stop_date = "",
    comptroller_entity = "ENTITY_COMPTROLLER"
  } = {}) {
    const newClient = {
      title: title || "",
      name_official: name_official || "",
      address: address || "",
      d112_frequency: d112_frequency,
      first_month: first_month,
      contract_start_date: contract_start_date,
      contract_stop_date: contract_start_date,
      comptroller_entity: comptroller_entity,

      _version: 2
    }
    return newClient
  },

}