var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "containers"
  }, [_c('div', {
    staticClass: "columns is-centered"
  }, [_c('div', {
    staticClass: "column"
  }, [_c('client-form', {
    on: {
      "saved": _vm.onFormCompete
    }
  })], 1)]), _c('b-loading', {
    attrs: {
      "is-full-page": _vm.isFullPage,
      "active": _vm.savingResult,
      "can-cancel": true
    },
    on: {
      "update:active": function ($event) {
        _vm.savingResult = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }