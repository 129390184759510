var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "section"
  }, [_c('list-of-reports', {
    attrs: {
      "reports": _vm.reportsList
    }
  }), _c('div', [_c('b-button', {
    on: {
      "click": _vm.createNewReport
    }
  }, [_vm._v("Raport nou")])], 1), _c('list-of-yearly-reports', {
    attrs: {
      "reports": _vm.yearlyReportsList
    }
  }), _c('div', [_c('b-button', {
    on: {
      "click": _vm.createNewYearlyReport
    }
  }, [_vm._v("Raport anual")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }