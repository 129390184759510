import firebase from "firebase/compat/app";

// import router from "../router";
// import {
//     ToastProgrammatic as Toast
// } from 'buefy'
// import to from "await-to-js";

export default {
    moduleName: 'auth',
    namespaced: true,
    state: {
        isAuthed: false,
        user: null,
    },
    getters: {
        isAuthed: state => state.isAuthed,
        user: state => state.user,
        // loginError: state => state.loginError,
        // loggingIn: state => state.loggingIn,

    },
    mutations: {

        SET_USER: (state, newUser) => {
            state.user = {
                email: newUser ? newUser.email : null,
                emailVerified: newUser ? newUser.emailVerified : null,
                displayName: newUser ? newUser.displayName : null,
                uid: newUser ? newUser.uid : null,
                isAnonymous: newUser ? newUser.isAnonymous : null,
                photoURL: newUser ? newUser.photoURL : null
            };

            state.isAuthed = Boolean(newUser)
        }
    },

    actions: {
        signOut() {
            firebase.auth().signOut()
        }
    }
}