<template>
  <div>
    <!-- <div>Lista de asociatii</div> -->
    
    <p>Total {{ countAllCustomers }} asociatii, din care {{ countActiveCustomers }} active.</p>

    <b-table
      :data="clientsList"
      :debounce-search="100"
      default-sort="title"
      sticky-header
      height="100%"
    >
      <b-table-column field="title" label="nume" :searchable="true" sortable>
        <template slot="searchable" slot-scope="props">
          <b-input
            v-model="props.filters.title"
            placeholder="Search..."
            icon="search"
            size="is-small"
            icon-right="close-circle"
            icon-right-clickable
            @icon-right-click="props.filters.title = null"
          />
        </template>

        <template v-slot="props">
          <router-link
            :to="{ name: 'ViewClient', params: { clientId: props.row.id } }"
            >{{ props.row.title || "fara nume" }}</router-link
          >
        </template>
      </b-table-column>

      <b-table-column
        field="name_official"
        label="denumirea oficiala"
        :searchable="true"
        sortable
      >
        <template slot="searchable" slot-scope="props">
          <b-input
            v-model="props.filters.name_official"
            placeholder="Search..."
            icon="search"
            size="is-small"
            icon-right="close-circle"
            icon-right-clickable
            @icon-right-click="props.filters.name_official = null"
          />
        </template>
        <template v-slot="props">{{ props.row.name_official }}</template>
      </b-table-column>

      <b-table-column
        field="address"
        label="adresa"
        :searchable="true"
        sortable
      >
        <template slot="searchable" slot-scope="props">
          <b-input
            v-model="props.filters.address"
            placeholder="Search..."
            icon="search"
            size="is-small"
            icon-right="close-circle"
            icon-right-clickable
            @icon-right-click="props.filters.address = null"
          />
        </template>

        <template v-slot="props">{{ props.row.address }}</template>
      </b-table-column>

      <b-table-column
        field="status"
        label="status"
        :searchable="true"
        sortable
      >
        <template slot="searchable" slot-scope="props">
          <b-input
            v-model="props.filters.contract_stop_date"
            placeholder="Search..."
            icon="search"
            size="is-small"
            icon-right="close-circle"
            icon-right-clickable
            @icon-right-click="props.filters.status = null"
          />
        </template>

        <template v-slot="props">
          <template v-if="!props.row.contract_stop_date ">active</template>
          <template v-else>stop {{ props.row.contract_stop_date}}</template>
          
        </template>
      </b-table-column>


      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"></b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script >
import { mapGetters } from "vuex";

export default {
  name: "ListClients",
  computed: {
    ...mapGetters({
      // user: "auth/user",
      clientsList: "clients/allAsArray",
    }),
 
    countAllCustomers(){
      return this.clientsList.length;
    },

    countActiveCustomers(){
      return this.clientsList
        .filter(client => !client.contract_stop_date)
        .length;
      return 99;
    }
  },
  methods: {
    clearIconClick(target) {
      console.log(target);
    },
  },
};
</script>

<style>
.container {
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.title {
  font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  display: block;
  font-weight: 300;
  font-size: 100px;
  color: #35495e;
  letter-spacing: 1px;
}

.subtitle {
  font-weight: 300;
  font-size: 42px;
  color: #526488;
  word-spacing: 5px;
  padding-bottom: 15px;
}

.links {
  padding-top: 15px;
}
</style>
