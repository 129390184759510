<template>
  <div
    id="main-page-content"
    class="is-flex is-flex-direction-column"
    style="width: 100%; max-height: 100vh"
  >
    <!-- buttons -->
    <div
      class="
        is-flex
        is-flex-direction-row
        is-justify-content-space-around
        is-align-content-center
        mb-2
      "
      style="background: #ebfffc"
    >
      <b-field horizontal label="Versiunea:">
        <b-select v-model="localFormVersion">
          <option value="4">v4</option>
          <option value="5">v5</option>
          <option value="6">v6</option>
          <option value="7">v7</option>
          <option value="8">v8</option>
        </b-select>
      </b-field>
      <div class="buttons" v-if="report">
        <b-button @click="saveChanges">Salvează</b-button>
        <b-button @click="cancelChanges">Anulează</b-button>
      </div>
    </div>

    <div class="columns is-centered m-0" style="max-height: 90vh">
      <!-- first column is the ReportForm -->
      <div class="column mr-2" style="overflow-y: auto; border: 1px solid">
        <div v-if="report" style="position: relative">
          <component
            v-if="formComponent"
            :is="formComponent"
            :data="report.data"
            :content="formContent"
            @update="updateReportData"
            @requestSave="saveReportData"
          ></component>
          <ComptrollerFormMissing
            v-else
            :version="localFormVersion"
          ></ComptrollerFormMissing>
        </div>
      </div>

      <!-- second column is the ReportSummary -->
      <div
        v-show="boolPreviewReport"
        class="column p-0"
        style="overflow-y: auto; border: 1px solid"
      >
        <div class="box has-background-light" ref="contentEl">
          <!-- dynamic component matching the form version -->
          <component
            :is="reportComponent"
            :data="localReportData"
            :formVersion="localFormVersion"
            :client="client"
            :previousMonthData="previousMonthReportData"
            :show-checks="boolChecksReport"
          />
          <!-- <comptroller-report-v-6
            :data="localReportData"
            :formVersion="localFormVersion"
            :client="client"
            :previousMonthData="previousMonthReportData"
          /> -->
        </div>
      </div>
    </div>
    <!-- show while trying to submit answer to server -->
    <b-loading
      :is-full-page="isFullPage"
      :active.sync="savingResult"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
// import to from "await-to-js";

import Report from "@/models/report";
import { ToastProgrammatic as Toast } from "buefy";

export default {
  name: "EditReportPage",

  computed: {
    ...mapGetters({
      getReportById: "reports/getById",
      getAllReports: "reports/allAsArray",
      getClientById: "clients/getById",
      selectedReportPage: "selection/reportPage",
      boolPreviewReport: "selection/boolPreviewReport",
      boolChecksReport: "selection/boolChecksReport",
    }),
    clientId() {
      return this.$route.params.clientId || null;
    },
    client() {
      if (!this.clientId) return null;
      return this.getClientById(this.clientId);
    },

    reportId() {
      return this.$route.params.reportId;
    },
    report() {
      if (!this.reportId) return null;
      return this.getReportById(this.reportId);
    },
    reportComponent() {
      const requiredComponentName =
        "ComptrollerReportV" + this.localFormVersion;
      // check if the required component is registered globally with Vue
      if (requiredComponentName in Vue.options.components)
        return requiredComponentName;
      return "ComptrollerReportMissing";
    },
    formComponent() {
      const requiredComponentName = "ComptrollerFormV" + this.localFormVersion;
      if (requiredComponentName in Vue.options.components)
        return requiredComponentName;
      return false;
    },
    formContent() {
      return Report.getFormByVersionString(this.localFormVersion);
    },

    // returns the repordData for the month before
    previousMonthReportData() {
      let prevMonth = parseInt(this.localReportData.report_month) - 1;
      let prevYear = parseInt(this.localReportData.report_year);

      if (prevMonth == 0) {
        prevMonth = 12;
        prevYear = prevYear - 1;
      }

      const allReports = this.getAllReports.filter(
        (r) =>
          r.data.report_month == prevMonth && r.data.report_year == prevYear
      );

      if (!allReports || !allReports.length) return null;
      if (!allReports[0] || !allReports[0].data) return null;
      return allReports[0].data;
    },
  },

  data: function () {
    return {
      isFullPage: false,
      savingResult: false,
      localReportData: null,
      localFormVersion: "0",
      availableReportComponents: ["4", "5", "6"],
    };
  },

  watch: {
    report: {
      handler: function (newVal) {
        this.localReportData = newVal ? newVal.data : null;
        this.localFormVersion = newVal ? newVal.formVersionString : "0";
      },
      immediate: true,
    },
    "localReportData.report_year": {
      handler: function (newVal) {
        newVal;
      },
    },
  },

  methods: {
    ...mapMutations({
      setActiveReportPage: "selection/setReportPage",
    }),

    async saveReportData(formResults) {
      console.log("generating report summary and saving report");
      this.savingResult = true;
      this.isErrorPosting = false;
      const patchedReport = JSON.parse(JSON.stringify(this.report, null, 2));
      patchedReport.data = formResults;
      // let subscribers know the pdf file is not ready for download
      patchedReport.waitingForPrint = true;
      patchedReport.client = JSON.parse(JSON.stringify(this.client, null, 2));

      const innerHtml = this.$refs.contentEl.innerHTML;
      console.log("extracted HTML from report", innerHtml);
      patchedReport.html = innerHtml;
      const reportObject = JSON.parse(JSON.stringify(patchedReport, null, 3));

      console.log("json object: ", JSON.stringify(reportObject, null, 3));

      this.isGeneratingReport = true;
      try {
        const newId = await this.$store.dispatch("reports/set", reportObject);
        console.log("updated report with id ", newId, reportObject);
        this.isGeneratingReport = false;
      } catch (error) {
        this.isGeneratingReport = false;
        console.error("submit feedback: error: ", error);
        this.isErrorPosting = true;
        Toast.open({
          message: "Ooops! Something went wrong and the data was not saved...",
          type: "is=danger",
          position: "is-bottom",
          duration: 5000,
        });
      }

      this.savingResult = false;
    },

    updateReportData(results) {
      // console.log("EditReport: the form updated report data");
      this.localReportData = results;

      // console.log("month is:", results.report_year, results.report_month);
    },

    async saveChanges() {
      try {
        await this.saveReportData(this.localReportData);
        this.$router.go(-1);
      } catch (error) {
        console.log(error);
      }
    },

    cancelChanges() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>
