<template>
  <router-view />
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from "vuex";
import store from "../store";

export default {
  name: "ClientLayout",

  async beforeRouteEnter(to, from, next) {
    console.log("ClientLayout: beforeRouteEnter", to.params.clientId);
    console.log(
      "ClientLayout: reports/openDBChannel for client",
      to.params.clientId
    );

    // closeDBChannel will not clear out the data in your current vuex module.
    // You can also close the connection and completely clear out the module;
    // removing all docs from your vuex module. (without deleting anything on the server, don't worry)
    // In this case do:
    // dispatch('moduleName/closeDBChannel', {clearModule: true})

    await store.dispatch(
      "reports/closeDBChannel",
      {
        clearModule: true
      },
      {
        root: true
      }
    );
      await store.dispatch(
      "yearlyReports/closeDBChannel",
      {
        clearModule: true
      },
      {
        root: true
      }
    );

    await store.dispatch("reports/openDBChannel", {
      clientId: to.params.clientId
    });
    await store.dispatch("yearlyReports/openDBChannel", {
      clientId: to.params.clientId
    });
    next();
  }
};
</script>


<style lang="scss" >
</style>