var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', [_vm._v("Versiune raport lipsă: V" + _vm._s(_vm.formVersion))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }