var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sidebar-page"
  }, [_c('section', {
    staticClass: "sidebar-layout"
  }, [_c('b-sidebar', {
    attrs: {
      "position": "static",
      "type": "is-secondary",
      "reduce": false,
      "mobile": "reduce",
      "open": "",
      "fullheight": true,
      "expand-on-hover": true
    }
  }, [_c('div', {
    staticClass: "p-1 pt-6"
  }, [_c('b-menu', {
    staticClass: "is-custom-mobile"
  }, [_c('b-menu-list', [_c('b-menu-item', {
    attrs: {
      "icon": "home",
      "icon-pack": "fas",
      "label": "Cenzor",
      "tag": "router-link",
      "to": {
        name: 'Home'
      },
      "active": _vm.$route.name == 'Home'
    }
  }), _c('b-menu-item', {
    attrs: {
      "icon": "list",
      "icon-pack": "fas",
      "label": "Lista asociatii",
      "tag": "router-link",
      "to": {
        name: 'ListClients'
      },
      "active": _vm.$route.name == 'ListClients'
    }
  }), _c('b-menu-item', {
    attrs: {
      "icon": "plus",
      "icon-pack": "fas",
      "label": "Adauga asociatie",
      "tag": "router-link",
      "to": {
        name: 'AddClient'
      },
      "active": _vm.$route.name == 'AddClient'
    }
  }), _vm.client ? _c('b-menu-item', {
    attrs: {
      "expanded": "",
      "label": _vm.client.title
    }
  }, [_c('b-menu-item', {
    attrs: {
      "icon": "home",
      "icon-pack": "fas",
      "label": "Vezi",
      "tag": "router-link",
      "to": {
        name: 'ViewClient',
        params: {
          clientId: _vm.client.id
        }
      },
      "active": _vm.$route.name == `ViewClient`,
      "exact": ""
    }
  }), _c('b-menu-item', {
    attrs: {
      "icon": "cog",
      "icon-pack": "fa",
      "label": "Setari",
      "tag": "router-link",
      "to": {
        name: 'EditClient',
        params: {
          clientId: _vm.client.id
        }
      },
      "active": _vm.$route.name == `EditClient`,
      "exact": ""
    }
  }), _c('b-menu-item', {
    attrs: {
      "icon": "folder",
      "label": "Rapoarte",
      "tag": "router-link",
      "to": {
        name: 'ListReports'
      },
      "active": _vm.$route.name == `ListReports`
    }
  })], 1) : _vm._e(), _vm.report ? _c('b-menu-item', {
    attrs: {
      "expanded": "",
      "label": `Raport ${_vm.report.data.report_year}-${_vm.report.data.report_month} (v${_vm.report.formVersionString})`
    }
  }, [_vm._l(_vm.reportFormPages, function (page, index) {
    return _c('b-menu-item', {
      key: page.name,
      attrs: {
        "label": `${index}. ${page.title}`,
        "active": page.name == _vm.selectedReportPage
      },
      on: {
        "click": function ($event) {
          return _vm.setActiveReportPage(page.name);
        }
      }
    });
  }), _c('div', {
    staticClass: "py-2 my-2 px-3"
  }, [_c('b-switch', {
    model: {
      value: _vm.statusPreviewReport,
      callback: function ($$v) {
        _vm.statusPreviewReport = $$v;
      },
      expression: "statusPreviewReport"
    }
  }, [_vm._v("Preview")])], 1), _c('div', {
    staticClass: "py-2 my-2 px-3"
  }, [_c('b-switch', {
    model: {
      value: _vm.statusChecksReport,
      callback: function ($$v) {
        _vm.statusChecksReport = $$v;
      },
      expression: "statusChecksReport"
    }
  }, [_vm._v("Verificări")])], 1)], 2) : _vm._e()], 1), _c('b-menu-list', {
    attrs: {
      "label": "Actions"
    }
  }, [_c('b-menu-item', {
    attrs: {
      "icon": "user",
      "icon-pack": "fas",
      "label": _vm.user.email,
      "tag": "router-link",
      "to": {
        name: 'Profile'
      }
    }
  }), _c('b-menu-item', {
    attrs: {
      "icon": "sign-out-alt",
      "icon-pack": "fas",
      "label": "Logout"
    },
    on: {
      "click": _vm.signOut
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "is-flex-grow-1 p-4"
  }, [_c('div', {
    staticClass: "is-flex is-flex-direction-column is-align-items-start is-align-content-end is-justify-content-end",
    staticStyle: {
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "is-flex-grow-1"
  }), _c('div', [_c('p', [_vm._v("Cenzor V" + _vm._s(_vm.appVersion))]), _c('p', [_vm._v("Database: " + _vm._s(_vm.datastore))]), _c('p', [_vm._v("Build: " + _vm._s(_vm.buildMode))])])])])]), _c('div', {
    staticClass: "p-1 pt-1",
    staticStyle: {
      "flex-grow": "1",
      "overflow": "auto"
    },
    attrs: {
      "id": "main-page"
    }
  }, [_c('router-view')], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }