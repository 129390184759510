// import {
//   format
// } from 'date-fns'

import formJson4 from "@/models/reportFormV4.json"
import formJson5 from "@/models/reportFormV5.json"
import formJson6 from "@/models/reportFormV6.json"
import formJson7 from "@/models/reportFormV7.json"
import formJson8 from "@/models/reportFormV8.json"

const formVersions = {
  "4": formJson4,
  "5": formJson5,
  "6": formJson6,
  "7": formJson7,
  "8": formJson8
}

const latestFormVersionString = Object.keys(formVersions)
  .sort((a, b) => parseInt(a) > parseInt(b))
  .pop();

const latestForm = formVersions[latestFormVersionString];

export default {
  newReport(data, client, formVersion=null) {

    // build a date that points to first day of previous month
    let defaultDate = new Date();
    defaultDate.setDate(0); // 0 will result in the last day of the previous month
    defaultDate.setDate(1); // 1 will result in the first day of the month

    const newReport = {};
    newReport.data = JSON.parse(JSON.stringify(data || {
      report_year: defaultDate.getFullYear(),
      report_month: defaultDate.getMonth() + 1 // +1 because getMonth is 0-indexed
    }, null, 3))
    newReport.client = JSON.parse(JSON.stringify(client || null, null, 3))

    // store the html of the generated report
    newReport.html = null

    // set formVersionString to latest available form version
    newReport.formVersionString = formVersion || latestFormVersionString;


    return newReport
  },

  reportFilename(report) {
    const filename = [
      "raport_cenzor_",
      report.client.title.split(/[^\w]/).join("_"),
      "_",
      report.data.report_year,
      "_",
      report.data.report_month.toString().padStart(2, "0"),
      ".pdf",
    ].join("");

    return filename;
  },


  //a function that returns the desired form version
  getFormByVersionString: (versionString) => formVersions[versionString],

  // the latest form
  currentForm: latestForm,

  // the latest form version number
  currentFormVersionString: latestFormVersionString

}