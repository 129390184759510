var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-loading', {
    attrs: {
      "active": _vm.isGeneratingReport
    },
    on: {
      "update:active": function ($event) {
        _vm.isGeneratingReport = $event;
      }
    }
  }), !Boolean(_vm.data) || !Boolean(_vm.client) ? _c('div', [_c('h1', {
    staticClass: "title has-text-centered"
  }, [_vm._v(_vm._s(_vm.reportTitle))]), _c('h2', [_vm._v("Eroare, date lipsa")])]) : _c('div', {
    staticClass: "content has-text-left",
    attrs: {
      "id": "report-content"
    }
  }, [_c('h1', {
    staticClass: "title has-text-centered"
  }, [_vm._v(_vm._s(_vm.reportTitle))]), _c('h2', {
    staticClass: "subtitle has-text-centered"
  }, [_vm._v(_vm._s(_vm.reportSubtitle))]), _c('h4', {
    staticClass: "subtitle has-text-centered"
  }, [_vm._v(_vm._s(_vm.reportPeriod))]), _vm.versionMismatch ? _c('h5', [_vm._v(" Atenție: raportul este v" + _vm._s(_vm.reportVersion) + " dar formularul este v" + _vm._s(_vm.formVersion) + "!! ")]) : _vm._e(), _vm._l(_vm.errors, function (err) {
    return _c('p', {
      key: err
    }, [_vm._v(_vm._s(err))]);
  }), _c('p', [_vm._v(" Comptroller SRL, reprezentată prin Ene Mihaela, în calitate de cenzor pentru " + _vm._s(_vm.clientData.name_official) + ", cu sediul în " + _vm._s(_vm.clientData.address) + ", am analizat și verificat documentele care stau la baza întocmirii rapoartelor și listelor de plată pentru luna " + _vm._s(_vm.reportPeriod) + ", conform cu Legea nr. 196/2018 privind înfiinţarea, organizarea şi funcţionarea Asociaţiilor de Proprietari. ")]), _c('p', {
    staticClass: "is-size-4"
  }, [_vm._v("Repartizarea facturilor")]), _c('p', [_vm._v(" Suma totală a facturilor repartizate în listă este în valoare de " + _vm._s(_vm.reportData.repartizarea_facturilor_suma_facturilor_repartizate) + " RON, iar totalul lunar din lista de plată este în valoare de " + _vm._s(_vm.reportData.repartizarea_facturilor_total_lunar_lista_de_plata) + " RON. ")]), _vm.sumaCotelorVsSumaFacturilorRepartizateEsteOk ? [_c('p', [_vm._v(" Suma totală a facturilor repartizate în listă este egală/aproximativ egală cu totalul lunar din lista de intreținere. ")])] : [_c('p', [_vm._v(" Diferența între suma cotelor apartamentelor și suma facturilor repartizate în listă este " + _vm._s(this.reportData.repartizarea_facturilor_suma_facturilor_repartizate - this.reportData.repartizarea_facturilor_total_lunar_lista_de_plata) + " RON. ")]), _vm._m(0), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.repartizarea_facturilor_neconcordante_sume), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)], _vm.reportData.repartizarea_facturilor_facturile_din_liste_sunt_corecte == 'true' ? [_c('p', [_vm._v(" Toate facturile repartizate în liste există fizic și sunt trecute cu sumele și datele corecte. ")])] : [_vm._m(1), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.repartizarea_facturilor_neconcordante_facturi_din_liste), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)], _vm.reportData.repartizarea_facturilor_facturile_din_fonduri_sunt_corecte == 'true' ? [_c('p', [_vm._v(" Toate facturile achitate din fonduri există fizic și sunt trecute cu sumele și datele corecte. ")])] : [_vm._m(2), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.repartizarea_facturilor_neconcordante_facturi_din_fonduri), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)], _c('p', {
    staticClass: "is-size-4"
  }, [_vm._v("Registrul de casă:")]), _c('p', [_vm._v(" Soldul de la casă este în valoare de " + _vm._s(_vm.reportData.registrul_de_casa_sold_casa) + " RON. Nu s-a efectuat numărarea lichidităților. ")]), _vm.reportData.registrul_de_casa_incasarile_sunt_corecte == 'true' ? [_c('p', [_vm._v(" Toate încasările în numerar sunt trecute cu sumele și datele corecte în Registrul de casă. ")])] : [_vm._m(3), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.registrul_de_casa_neconcordante_incasari), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)], _vm.reportData.registrul_de_casa_platile_sunt_corecte == 'true' ? [_c('p', [_vm._v(" Toate plățile în numerar sunt trecute cu sumele și datele corecte în Registrul de casă. ")])] : [_vm._m(4), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.registrul_de_casa_neconcordante_plati), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)], _vm.reportData.registrul_de_casa_este_corect_pe_zile == 'true' ? [_c('p', [_vm._v(" Fiecare zi trecuta în Registrul de casă are totalul de plăti corect, totalul de încasări corect și totalul zilnic corect. ")])] : [_vm._m(5), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.registrul_de_casa_neconcordante_pe_zile), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)], parseInt(_vm.reportData.registrul_de_casa_sold_casa) > 1000 && _vm.reportData.popriri_conturi == 'true' ? [_c('p', [_vm._v(" Soldul de la casă depașește plafonul stabilit de lege în valoare de 1000 de RON, dar valoarea depașită nu poate fi depusă în contul de la bancă deoarece pe contul Asociației există popriri. ")])] : _vm._e(), parseInt(_vm.reportData.registrul_de_casa_sold_casa) > 1000 && _vm.reportData.popriri_conturi == 'false' ? [_vm._m(6)] : _vm._e(), _c('p', {
    staticClass: "is-size-4"
  }, [_vm._v("Registrul de bancă:")]), _vm.reportData.registrul_de_banca_vs_extras_cont_este_ok == 'true' ? [_c('p', [_vm._v(" Extrasul de cont de la bancă este inregistrat corect cu datele și sumele corecte în Registrul de bancă/Registrul jurnal. ")])] : [_vm._m(7), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.registrul_de_banca_neconcordante), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)], !!_vm.reportData.registrul_de_banca_sold_banca ? [_c('p', [_vm._v(" Soldul de la bancă este în valoare de " + _vm._s(_vm.reportData.registrul_de_banca_sold_banca) + " RON. ")])] : [_c('p', [_vm._v("LIPSEȘTE soldul de la bancă !!!")])], _c('p', {
    staticClass: "is-size-4"
  }, [_vm._v("Situația de Activ/Pasiv:")]), _c('p', [_vm._v(" Totalul elementelor de Activ este în valoare de: " + _vm._s(_vm.reportData.activ_pasiv_total_activ) + " RON. ")]), _c('p', [_vm._v(" Totalul elementelor de Pasiv este în valoare de: " + _vm._s(_vm.reportData.activ_pasiv_total_pasiv) + " RON. ")]), _c('p', [_vm._v(" Soldul din Registrul de bancă la data emiterii Situației de Activ/Pasiv este " + _vm._s(_vm.reportData.activ_pasiv_sold_banca) + " RON. ")]), _c('p', [_vm._v(" Soldul din Registrul de casă la data emiterii Situației de Activ/Pasiv este " + _vm._s(_vm.reportData.activ_pasiv_sold_casa) + " RON. ")]), _vm.situatiaActivePasiveEsteEchilibrata ? [_c('p', [_vm._v("Situația de Activ/Pasiv este echilibrată.")])] : [_vm._m(8), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.activ_pasiv_neconcordante), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)], _c('p', {
    staticClass: "is-size-4"
  }, [_vm._v("Ștatul de salarii:")]), _vm.reportData.statul_de_salarii_contributii_corecte == 'true' ? [_c('p', [_vm._v("Contribuțiile calculate pe ștatul de plată sunt corecte.")])] : [_vm._m(9), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.statul_de_salarii_neconcordante_contributii), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)], _vm.reportData.statul_de_salarii_d112_este_corecta == 'true' ? [_c('p', [_vm._v("Declarația 112 este întocmită corespunzător.")])] : [_vm._m(10), _c('ul', _vm._l(_vm.formatListItems(_vm.reportData.statul_de_salarii_neconcordante_d112), function (item) {
    return _c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)], _vm.reportData.statul_de_salarii_recipisa_d112_este_ok == 'true' ? [_c('p', [_vm._v(" Există recipisa de depunere fără erori de validare pentru Declaratia 112. ")])] : [_vm._m(11)]], 2)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(":")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la repartizarea facturilor: ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la facturile achitate din fonduri: ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la încasarile din Registrul de casă: ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la plațile din Registrul de casă: ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la Registrul de casă:")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" Soldul de la casă este mai mare decât plafonul prevăzut de lege, în valoare de 1000 de RON; "), _c('strong', [_vm._v("recomandăm")]), _vm._v(" depunerea diferenței în contul de la bancă. ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la Registrul de bancă:")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la situația de Activ/Pasiv: ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la calculul contribuțiilor pe ștatul de plată: ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Observații")]), _vm._v(" cu privire la calculul contribuțiilor pe ștatul de plată: ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Nu am primit")]), _vm._v(" recipisa de depunere fără erori de validare pentru Declaratia 112. ")]);

}]

export { render, staticRenderFns }