<template>
  <div class="containers">
    <div class="columns is-centered">
      <div class="column">
        <!-- <div class>
          <x-survey :content="clientForm" @completed="onFormCompete"></x-survey>
        </div>-->
        <client-form @saved="onFormCompete"></client-form>
      </div>
    </div>

    <!-- show while trying to submit answer to server -->
    <b-loading
      :is-full-page="isFullPage"
      :active.sync="savingResult"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import Client from "@/models/client";
import ClientForm from "@/components/ClientForm.vue";
// import { mapGetters } from 'vuex'

// import XSurvey from "../../components/XSurvey.vue";
// import { ToastProgrammatic as Toast } from 'buefy'
export default {
  name: "AddClientPage",

  components: { ClientForm },
  data: function () {
    return {
      isFullPage: false,
      savingResult: false,
      clientForm: Client.form,
    };
  },

  methods: {
    async onFormCompete(result) {
      console.log(
        "form has completed, data is : ",
        JSON.parse(JSON.stringify(result))
      );
      const newClient = Client.newClient(result);

      this.saveInProgress = true;

      try {
        // save the project to Firestore
        const newId = await this.$store.dispatch("clients/set", newClient);
        console.log("saved project with id ", newId, newClient);

        this.$router.push({ name: "ViewClient", params: { clientId: newId } });
      } catch (error) {
        // this.track('error', {
        //   type: 'error saving client',
        //   error: error,
        //   project: newClient
        // })
        console.log("ERROR saving new client", error);
        this.saveInProgress = false;
        this.savingError = error;
      }
    },
  },
};
</script>

<style>
</style>
